import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import banner from "../assets/banner.jpeg";
import Modal from "react-bootstrap/Modal";
import {useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {
    BiFullscreen, BsPeopleFill,
    FaChalkboardTeacher, FaKeyboard, FaQuestion, FaThumbsUp, FaTrophy,
    FiInstagram,
    FiYoutube, GiArrowCursor, GrTrophy,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, RiSurveyLine
} from "react-icons/all";

import Unity, {UnityContext} from "react-unity-webgl";
import Header from "./Header";

import sguLogo from "../assets/favicon.png"
import ophBanner from "../assets/oph logo.png"
import sguWhite from "../assets/sgu-logo-white-300x139.png"
import Footer from "./Footer";
import Palette from "../utils/Palette";

import {isSafari, isOpera, isMobile} from "react-device-detect";
import Swal from "sweetalert2";
import {Popover} from "bootstrap";
import Tooltip from "react-bootstrap/cjs/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Card} from "react-bootstrap";
import logo from "../assets/logo.png";
import logoWhite from "../assets/logo_white.png";
import {HiOutlineBriefcase, HiOutlineMail, HiOutlinePhone} from "react-icons/hi";
import CustomButton from "./Reusable/CustomButton";
import styles from "../values/styles";
import User from "../models/User";
import moment from "moment";
import banner0 from "../assets/AerialFront.png"
import Schedule from "../models/Schedule";

const unityContext = new UnityContext({
    loaderUrl: "build/Build.loader.js",
    dataUrl: "build/Build.data.unityweb",
    frameworkUrl: "build/Build.framework.js.unityweb",
    codeUrl: "build/Build.wasm.unityweb",
});


unityContext.on("error", function (message) {
    alert("An error occured. Please try refreshing the page or use another browser.")
});

const TUTORIAL_NODES = [
    {
        title: "Welcome",
        content: "Welcome to IIDEC 2021!",
        icon: <img
            style={{
                marginTop: 15,
                height: "8em",
                objectFit: "contain"
            }}
            src={logoWhite}/>
    },
    {
        title: "Controls",
        content: "Use the WASD keys to move your avatar around the virtual exhibition area.",
        icon: <FaKeyboard
            style={{
                marginTop: 15
            }}
            size={"5em"}/>
    },
    {
        title: "Interact",
        content: "By pressing the screen, you can interact with different object in the exhibition area.",
        icon: <GiArrowCursor
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
    {
        title: "Meet",
        content: "You can meet your friends participating in the virtual room!",
        icon: <BsPeopleFill
            style={{
                marginTop: 30
            }}
            size={"5em"}/>
    },
    // {
    //     title: "Games",
    //     content: "Find treasures from 8 December - 9 December 2021 for a chance to get a doorprize!",
    //     icon: <FaTrophy
    //         style={{
    //             marginTop: 30
    //         }}
    //         size={"5em"}/>
    // },
]

export default function LandingPage(props) {
    const history = useHistory();
    const [isLoginShown, setLoginShown] = useState(false)

    const [progression, setProgression] = useState(
        0)
    const [tutorialModal, setTutorialModal] = useState(false)

    const [tutorialPage, setTutorialPage] = useState(0)

    const [isPopperShown, setIsPopperShown] = useState(true);

    const [profile, setProfile] = useState({});

    const userModel = new User();

    const [daysLeft, setDaysLeft] = useState(null)
    const [hourLeft, setHourLeft] = useState(null)
    const [minuteLeft, setMinuteLeft] = useState(null)
    const [secondLeft, setSecondLeft] = useState(null)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [adUrl, setAdUrl] = useState("")
    const [adImage, setAdImage] = useState("")

    let scheduleModel = new Schedule()

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        getAd()


        let scheduleTimer = setInterval(() => {
            // getAd()
        }, 60000)

        return () => clearInterval(scheduleTimer)

    }, [])

    const getAd = async () => {
        try {

            let ad = await scheduleModel.getAd()
            setAdImage(ad.image_url)
            setAdUrl(ad.video_url)

        } catch (e) {
            console.log(e)
            return {image_url: "", video_url: ""}
        }
    }

    useEffect(() => {
        let timer = setInterval(() => {
            console.log()
            let dayDiff = moment().diff("2021-12-08 07:00", "days");
            let hourDiff = moment().diff("2021-12-08 07:00", "hour");
            let minuteDiff = moment().diff("2021-12-08 07:00", "minute");
            let secondDiff = moment().diff("2021-12-08 07:00", "second");

            if (dayDiff <= 0) {
                setDaysLeft(dayDiff * -1)
            }

            if (hourDiff <= 0) {
                setHourLeft(hourDiff * -1 % 24)
            }

            if (minuteDiff <= 0) {
                setMinuteLeft(minuteDiff * -1 % 60)
            }

            if (secondDiff <= 0) {
                setSecondLeft(secondDiff * -1 % 60)
            }

        }, 1000)

        return () => clearInterval(timer)

    }, [])


    useEffect(function () {
        if (!localStorage.getItem('token')) {
            history.push('/login')
        } else {
            unityContext.on("progress", function (progression) {
                setProgression(progression);
            });
        }

        if (!localStorage.getItem('first_visit')) {
            localStorage.setItem('first_visit', "true")
            setTutorialModal(true)
        }

        if (isSafari || isMobile || isOpera) {
            Swal.fire({
                title: 'This browser may not support the virtual expo',
                text: 'For the best experience, please consider using the desktop version of Google Chrome or Microsoft Edge.',
                icon: 'info',
                confirmButtonText: 'OK'
            }).then((result) => {
                setLoginShown(true)
            })
        }

    }, []);

    const getProfile = async () => {
        try {
            const profileTemp = await userModel.getMyProfile()

            console.log('profileTemp', profileTemp)

            setProfile(profileTemp)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProfile()
        }
    }, [])

    return <>
        <Modal
            show={tutorialModal}
            size={"xs"}>

            <Modal.Body style={{
                backgroundColor: Palette.IIDEC_RED,
                height: 350,
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
            >
                <div style={{
                    fontSize: "1.5em"
                }}>
                    {TUTORIAL_NODES[tutorialPage].title}
                </div>


                {TUTORIAL_NODES[tutorialPage].icon}


                <div style={{
                    width: "80%",
                    textAlign: "center",
                    marginTop: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    {TUTORIAL_NODES[tutorialPage].content}
                </div>

                <div style={{
                    marginTop: 20,
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    {
                        TUTORIAL_NODES.map((obj, index) => {
                            return <div style={{
                                width: 10,
                                height: 10,
                                borderRadius: 20,
                                marginLeft: 5,
                                marginRight: 5,
                                backgroundColor: tutorialPage === index ? "white" : Palette.IIDEC_LIGHT_PALE_RED
                            }}/>
                        })
                    }

                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                    width: "100%"
                }}>
                    {
                        tutorialPage !== 0 &&
                        <div
                            onClick={() => {
                                setTutorialPage(tutorialPage - 1)
                            }}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            Previous
                        </div>
                    }


                    <div style={{
                        flex: 1,
                        width: "100%",
                        height: 10,
                    }}>

                    </div>

                    {
                        tutorialPage === TUTORIAL_NODES.length - 1 ?
                            <div
                                onClick={() => {
                                    setTutorialModal(false)
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                Finish
                            </div>
                            :
                            <div
                                onClick={() => {
                                    setTutorialPage(tutorialPage + 1)
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                Next
                            </div>
                    }


                </div>

            </Modal.Body>

        </Modal>
        <Container
            style={{
                padding: 0
            }}
            fluid>
            <Header
                mainMenu
                loginButton={false}
                openLogin={() => setLoginShown(true)}/>
        </Container>
        <Container fluid>

            <Row style={{
                marginTop: 110,
                display: "flex",
                justifyContent: "center",
            }}
            >
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 0
                    }}
                    md={12}>
                    {
                        progression < 1 &&
                        <div style={{
                            height: "calc(100vh - 160px)",
                            width: "100%",
                            background: `url(${banner0})`,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            backgroundSize: "cover"
                        }}>

                            {/*<div style={{*/}
                            {/*    backgroundColor : "white",*/}
                            {/*    paddingLeft : 10,*/}
                            {/*    paddingRight : 10,*/}
                            {/*    borderRadius : 10*/}
                            {/*}}>*/}
                            {/*    <img*/}
                            {/*        style={{*/}
                            {/*            width: 500,*/}
                            {/*            height: 250,*/}
                            {/*            objectFit : "contain"*/}
                            {/*        }}*/}
                            {/*        src={logo}/>*/}
                            {/*</div>*/}


                            <div style={{
                                width: 200,
                                color: Palette.IIDEC_RED,
                                height: 15
                            }}>
                                <div style={{
                                    width: progression * 100 + "%",
                                    backgroundColor: "white",
                                    height: 15
                                }}>

                                </div>
                            </div>

                            <div style={{
                                marginTop: 10,
                                marginBottom: 50,
                                color: "white"
                            }}>
                                Loading the Virtual Exhibition
                            </div>

                        </div>
                    }

                    <Unity
                        style={{
                            height: progression >= 1 ? "calc(100vh - 160px)" : 0,
                            width: "100vw",
                        }}
                        unityContext={unityContext}/>

                    {
                        (adImage && progression >= 1) ? <div style={{
                                position: "absolute",
                                bottom: 80,
                                left: 20,
                                display : "flex",
                                flexDirection : "column",

                            }}>
                                <a
                                    style={{textAlign : "center"}}
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        setAdUrl("")
                                        setAdImage("")
                                    }}
                                    href={"#"}>
                                    <small style={{textAlign : "center", marginBottom : 5, color : "white"}}>
                                        Close Ad (x)
                                    </small>
                                </a>

                                <a
                                    href={adUrl} target={"_blank"}>
                                    <img
                                        style={{
                                            width: 200,
                                            height: 200,
                                            objectFit: "contain"
                                        }}
                                        src={adImage}/>

                                </a>
                            </div>
                            :
                            ""
                    }


                </Col>


                <Col md={9}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: 10
                    }}
                    >
                        <div style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            {
                                progression >= 1 &&
                                <Button
                                    style={{
                                        height: "2em",
                                        width: "2em",
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: "center",
                                        padding: 0
                                    }}
                                    onClick={() => {
                                        unityContext.setFullscreen(true);
                                    }}
                                >
                                    <BiFullscreen color={"white"}/>
                                </Button>
                            }

                        </div>

                        <Button
                            style={{
                                marginRight: 5,
                                height: "2em",
                                width: "2em",
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "center",
                                padding: 0
                            }}
                            onClick={() => {
                                setTutorialPage(0)
                                setTutorialModal(true)
                            }}
                        >
                            <FaQuestion color={"white"}/>
                        </Button>

                    </div>

                </Col>
            </Row>
        </Container>
        <Container
            style={{padding: 0, marginTop: 50}}
            fluid>
            <Footer/>
        </Container>
    </>
}
