import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import styles from "../values/styles";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, { useEffect, useRef, useState } from "react";
import {
    AiOutlinePlayCircle, BsChevronDown, BsMegaphoneFill, BsPlayCircleFill,
    FaChalkboardTeacher, FaMedal, FaUsers,
    FiInstagram,
    FiYoutube, GiPartyPopper, GiPresent,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, IoMdTrophy
} from "react-icons/all";
import Header from "./Header";
import User from "../models/User";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"

import Footer from "./Footer";

import InstagramFeed from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

import agendaImage1 from "../assets/jadwalbaru/2/Day 1 - 14 Dec 2021 - update 141221.jpg"
import agendaImage2 from "../assets/jadwalbaru/2/Day 2 - 14 Dec 2021.jpg"

let requestCountDownTime = 0

export default function ConferenceAgendaPage(props) {
    const homeRef = useRef(null)
    const conferenceAgendaRef = useRef(null)
    const sponsorshipRef = useRef(null)

    const history = useHistory();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const isMobile = windowWidth < 768

    useEffect(() => {

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return <div>

        <Header
            clearMode={false}
            activeHeader={"conference-agenda"}
            loginButton={true}
            joinExpo={() => {
                history.push("/login_")
            }} />

        <div style={{
            marginTop: 100,
            minHeight: '70vh',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }}>

            <Container>
                <Row>
                    <Col md={12}>
                        <h4>
                            <div style={{
                                fontFamily: 'conthrax-bold',
                                textAlign: "center",
                                marginBottom: 30,
                                marginTop: isMobile ? 50 : 100
                            }}>
                                Day 1 - 14 December 2021
                            </div>
                        </h4>

                        <img
                            style={{
                                width : "100%",
                                objectFit : "contain"
                            }}
                            src={agendaImage1}/>
                        {/*<div className={"table-warp"}>*/}
                        {/*    <table className="table table-responsive table-striped table-bordered table-converence-agenda table-converence-agenda-first">*/}
                        {/*        <thead>*/}
                        {/*            <tr>*/}
                        {/*                <th style={{ width: '15%' }}>Time</th>*/}
                        {/*                <th style={{ width: '10%' }}>Duration</th>*/}
                        {/*                <th style={{ width: '20%' }}>Activity</th>*/}
                        {/*                <th style={{ width: '30%' }}>Speaker</th>*/}
                        {/*                <th style={{ width: '30%' }}>Remarks</th>*/}

                        {/*            </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">07.00 - 09.00</th>*/}
                        {/*                <td></td>*/}
                        {/*                <td colspan="3" className={"text-center"}>*/}
                        {/*                    VIDEO LOOPING (OBB)*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.00 - 09.05</th>*/}
                        {/*                <td>5mins</td>*/}
                        {/*                <td colspan="3" className={"text-center"}>*/}
                        {/*                    Opening by Moderator/MC & Doa*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">09.05 - 09.10</th>*/}
                        {/*                <td>5mins</td>*/}
                        {/*                <td colspan="3" className={"text-center"}>*/}

                        {/*                    Indonesia Raya*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.10 - 09.15</th>*/}
                        {/*                <td>5mins</td>*/}
                        {/*                <td colspan="3" className={"text-center"}>*/}
                        {/*                    Opening Video*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}

                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.15 - 09.35</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>*/}
                        {/*                    Welcoming Speech*/}
                        {/*                </td>*/}
                        {/*                <td>Ir. Dadan Moh. Nurjaman, M.T. IPU <br />Kepala OR PPT</td>*/}
                        {/*                <td>The Role of BRIN—OR PPT in*/}
                        {/*                    Disaster Management</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.35 - 09.55</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>*/}
                        {/*                    Opening Speech*/}
                        {/*                </td>*/}
                        {/*                <td>* Dr. Laksana Tri Handoko, M.Sc <br />Kepala BRIN</td>*/}
                        {/*                <td>The Role of Research and Innovation in Disaster Management</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.55 - 10.15</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>*/}
                        {/*                    Keynote Speech*/}
                        {/*                </td>*/}
                        {/*                <td>BRIN</td>*/}
                        {/*                <td>The Guidance of Research and Innovation in Disaster Management</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope"></th>*/}
                        {/*                <td></td>*/}
                        {/*                <td colspan="3">Opening IIDEC 2021</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">10.15 - 10.20</th>*/}
                        {/*                <td>5mins</td>*/}
                        {/*                <td colspan="3" className={'text-center'}>MC Bridging</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">10.20 - 11.50</th>*/}
                        {/*                <td>90mins</td>*/}
                        {/*                <td colspan="3" className={'text-center'}>Disscussion 1 <br /> Panel Moderator : Dr.Drs Andi Eka Sakya, M.Eng</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">10.20 - 10.40</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td rowspan="3">Discussion 1- <br /> Penalists</td>*/}

                        {/*                <td>* Rieke Diah Pitaloka, M.Hum <br /> Anggota DPR</td>*/}

                        {/*                <td>The Role of Legislatives on Disaster Management in Indonesia</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">10.40 - 11.00</th>*/}
                        {/*                <td>20mins</td>*/}

                        {/*                <td>* Letnan Jenderal TNI Suharyanto,*/}
                        {/*                    S.Sos., M.M.*/}
                        {/*                    Kepala BNPB</td>*/}

                        {/*                <td>The Role of BMKG in Supporting the Development of Disaster Technology</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">11.00 - 11.20</th>*/}
                        {/*                <td>20mins</td>*/}

                        {/*                <td>* Prof,Ir.Dwitrikora Karniwati, M.Sc., Ph.D <br />*/}
                        {/*                    Kepala BMKG</td>*/}

                        {/*                <td>The Role of BMKG in Supporting the Earthquake and Tsunami Monitoring</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">11.20 - 11.50</th>*/}
                        {/*                <td>30mins</td>*/}
                        {/*                <td colspan="3" className={'text-center'}>QNA</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">11.50 - 12.45</th>*/}
                        {/*                <td>50mins</td>*/}
                        {/*                <td colspan="3" className={'text-center'}>ISHOMA</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">12.45 - 14.15</th>*/}
                        {/*                <td>90mins</td>*/}
                        {/*                <td colspan="3" className={'text-center'}>Disscussion 2 <br /> Panel Moderator : Dr.Mulyo Harris Pradono, M.Sc</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">12.45 - 13.10</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td rowspan="4">Discussion 2-<br /> Penalists</td>*/}

                        {/*                <td>Dr. Dipl. Ing. Michael Andreas Purwadi, DEA INATEWS BRIN</td>*/}
                        {/*                <td>Program Pengembangan INATEWS di Indonesia</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">13.10 - 13.30</th>*/}
                        {/*                <td>20mins</td>*/}

                        {/*                <td>* Prof. Josaphat Tetuko Sri Sumantyo, Ph.D <br />Chiba University, Japan</td>*/}
                        {/*                <td>Syntetic Apeture Radar For Disaster Application</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">13.30 - 13.50</th>*/}
                        {/*                <td>20mins</td>*/}

                        {/*                <td>* Dr. Yasushi Ishihara<br />JAMSTEC, Japan</td>*/}
                        {/*                <td>Cable Base Tsunameter Implementation in Japan</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">13.50 - 14.10</th>*/}
                        {/*                <td>20mins</td>*/}

                        {/*                <td>* Prof. Bruce Howe<br />University of Hawai'at Manoa</td>*/}
                        {/*                <td>Smart Cable Based Tsunameter for Tsunami Disaster Risk Reduction</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr><th scope="row" class="scope">14.10 - 14.30</th>*/}
                        {/*                <td>30mins</td>*/}
                        {/*                <td colspan="3" className={'text-center'}>QNA</td>*/}
                        {/*            </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}


                        <h4>
                            <div style={{
                                fontFamily: 'conthrax-bold',
                                textAlign: "center",
                                marginBottom: 30,
                                marginTop: 70
                            }}>
                                Day 2 - 15 December 2021
                            </div>
                        </h4>

                        <img
                            style={{
                                width : "100%",
                                objectFit : "contain"
                            }}
                            src={agendaImage2}/>
                        {/*<div className={"table-warp"}>*/}
                        {/*    <table className="table table-responsive table-striped table-bordered table-converence-agenda table-converence-agenda-second">*/}
                        {/*        <thead>*/}
                        {/*            <tr>*/}
                        {/*                <th style={{ width: '15%' }}>Time</th>*/}
                        {/*                <th style={{ width: '10%' }}>Duration</th>*/}
                        {/*                <th style={{ width: '20%' }}>Activity</th>*/}
                        {/*                <th style={{ width: '30%' }}>Speaker</th>*/}
                        {/*                <th style={{ width: '30%' }}>Remarks</th>*/}

                        {/*            </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">07.00 - 09.00</th>*/}
                        {/*                <td></td>*/}
                        {/*                <td colspan="3">*/}
                        {/*                    VIDEO LOOPING (OBB)*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.00 - 09.05</th>*/}
                        {/*                <td>5mins</td>*/}
                        {/*                <td colspan="3">*/}
                        {/*                    Opening Moderator/MC*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.05 - 09.25</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>Keynote Speech 2</td>*/}
                        {/*                <td>*/}
                        {/*                    * H.Ganjar Pranowo, S.H., M.I.P. <br /> Gubernur Jawa Tengah*/}
                        {/*                </td>*/}
                        {/*                <td>The Role of Local Goverments in Disaster Management</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.25 - 10.55</th>*/}
                        {/*                <td>90mins</td>*/}
                        {/*                <td colspan="3">Discussion 3 <br />Panel Moderator : Dr.M.Ilyas ST. M.Sc</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.25 - 09.45</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td rowspan="3">Discussion 3 - Panelists</td>*/}
                        {/*                <td>Bupati Kabupaten Manggarai Barat</td>*/}
                        {/*                <td>The Role BPBD in Regency of Manggarai Barat on Disaster Management</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">09.45 - 10.10</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>BPBD Provinsi DKI Jakarta</td>*/}
                        {/*                <td>The Role of BPBD in Province of DKI Jakarta on Disaster Management</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">10.10 - 10.30</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>* BPBD Provinsi Bali</td>*/}
                        {/*                <td>The Role of BPBD in Province of Bali on Disaster Management</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">10.30 - 10.55</th>*/}
                        {/*                <td>25mins</td>*/}
                        {/*                <td colspan="3">QNA</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">10.55 - 11.15</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>Keynote Speech 3</td>*/}
                        {/*                <td>* Erick Thohir <br /> Menteri BUMN RI</td>*/}
                        {/*                <td>The Role of BUMN dan Privates in Disaster Management</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">11.15 - 12.45</th>*/}
                        {/*                <td>90mins</td>*/}
                        {/*                <td colspan="3">Discussion 4 <br />Nur Hidayat, S.T., M.Si</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">11.15 - 11.40</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td rowspan="3">Discussion 4 - <br /> Panelists</td>*/}
                        {/*                <td>PT PAL</td>*/}
                        {/*                <td>The Contribution of PT PAL in InaBuoy Development</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">11.40 - 12.00</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>PT Hutama Karya</td>*/}
                        {/*                <td>The Contribution of PT Hutama Karya in Disaster Management</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">12.00 - 12.20</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>PT Adaro Energy</td>*/}
                        {/*                <td>The Contribution of PT Adaro Energy on Disaster Management in Mining Area</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">12.20 - 12.45</th>*/}
                        {/*                <td>25mins</td>*/}
                        {/*                <td colspan="3">*/}
                        {/*                    QNA*/}
                        {/*                </td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">12.45 - 13.30</th>*/}
                        {/*                <td>45mins</td>*/}
                        {/*                <td colspan="3">*/}
                        {/*                    ISHOMA*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">13.30 - 13.50</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>Keynote Speech </td>*/}
                        {/*                <td>Ketua Lembaga Penganggulangan Bencana dan Perubahan Iklim Pengurus Besar Nahdatul Ulama</td>*/}
                        {/*                <td>The Role of NU on the Disaster Management in Indonesia</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">13.50 - 15.20</th>*/}
                        {/*                <td></td>*/}
                        {/*                <td colspan="3">*/}
                        {/*                    Discussion 5 <br />*/}
                        {/*                    Panel Moderator : *Dr.Ir.Dwi Abdi Tiwi, MUP*/}
                        {/*                </td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">13.50 - 14.10</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td rowspan="3">Discussion 5 - <br /> Panelists</td>*/}
                        {/*                <td>* Een Irawan Putra <br /> (Rekam Nusantara Foundation)</td>*/}
                        {/*                <td>The Role of NGO in the conversation of lake and catchment areas</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">14.10 - 14.30</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>* Ir.Ardito M.Kodiyat, M.Sc <br />(UNESCO)</td>*/}
                        {/*                <td>The Role of UNESCO in Tsunami Disaster Management</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">14.30 - 14.50</th>*/}
                        {/*                <td>20mins</td>*/}
                        {/*                <td>* Dr.Agus Maryono <br />(UGM)</td>*/}
                        {/*                <td>Community Empowerment in River Conversation</td>*/}

                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">14.50 - 15.20</th>*/}
                        {/*                <td>30mins</td>*/}
                        {/*                <td colspan="3">QNA</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">15.20 - 15.30</th>*/}
                        {/*                <td>10mins</td>*/}
                        {/*                <td colspan="3">Close Remark</td>*/}
                        {/*            </tr>*/}
                        {/*            <tr>*/}
                        {/*                <th scope="row" class="scope">15.30 - 15.45</th>*/}
                        {/*                <td>15mins</td>*/}
                        {/*                <td colspan="3">Closing Ceremony</td>*/}
                        {/*            </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}

                        <p style={{
                            textAlign : "center"
                        }}>
                            *To Be Confirmed
                        </p>

                    </Col>
                </Row>
            </Container>



        </div>

        <Footer />

    </div>
}
