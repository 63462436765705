import ApiRequest from "../utils/ApiRequest";

export default class Forms {

    postContactUsForm = async (body) => {
        return await ApiRequest.set(`/v1/contact_form`, "POST", body);
    }

    postSponsorshipForm = async (body) => {
        return await ApiRequest.set(`/v1/sponsorship_form`, "POST", body);
    }

}
