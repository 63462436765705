import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import styles from "../values/styles";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, {useEffect, useRef, useState} from "react";
import {
    AiOutlinePlayCircle, BsChevronDown, BsMegaphoneFill, BsPlayCircleFill,
    FaChalkboardTeacher, FaMedal, FaUsers,
    FiInstagram,
    FiYoutube, GiPartyPopper, GiPresent,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, IoMdTrophy
} from "react-icons/all";
import Header from "./Header";
import User from "../models/User";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import {useHistory} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from "react-responsive-carousel";

import ReactPlayer from "react-player";
import {Card} from "react-bootstrap"

import banner0 from "../assets/IIDEC_WEB_BANNER_POLOS.jpg"
import aboutUsImage from "../assets/Image about IIDEC.jpg"

import featureImages from "../assets/orange_innovate.png"

import Palette from "../utils/Palette";
import Footer from "./Footer";

import InstagramFeed from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

import moment from "moment"
import CustomButton from "./Reusable/CustomButton";
import logo from "../assets/logo.png";
import {HiOutlineBriefcase, HiOutlineMail, HiOutlinePhone} from "react-icons/hi";
import ReactCodeInput from "react-code-input";

import virtualConferenceImage from "../assets/Virtual Conference.jpg"
import virtualExhibitionImage from "../assets/Virtual Exhibition.JPG"
import {Link, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll'
import Forms from "../models/Forms";
import Util from "../utils/Util";

let requestCountDownTime = 0

export default function SponsorshipPage(props) {

    const history = useHistory();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [contactFullName, setContactFullName] = useState(null)
    const [contactCompany, setContactCompany] = useState(null)
    const [contactEmail, setContactEmail] = useState(null)
    const [contactPhone, setContactPhone] = useState(null)
    const [contactJobTitle, setContactJobTitle] = useState(null)

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const isMobile = windowWidth < 768

    useEffect(() => {

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const contactFormSubmit = async()=>{
        try{
            let forms = new Forms()

            if(!contactFullName){
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text : "Fullname cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if(!contactEmail){
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text : "Email cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if(!Util.validateEmail(contactEmail)){
                return Swal.fire({
                    title: 'Please check your entry',
                    text : "Invalid Email",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if(!contactJobTitle){
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text : "Subject cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            let params = {
                email : contactEmail,
                full_name : contactFullName,
                company : contactCompany,
                job_title : contactJobTitle,
                phone_num : contactPhone,
            }

            await forms.postSponsorshipForm(params)

            Swal.fire({
                title: 'Your form has been submitted',
                text : "Thank you for your participation",
                icon: 'success',
                confirmButtonText: 'OK'
            })

            setContactEmail(null)
            setContactFullName(null)
            setContactCompany(null)
            setContactJobTitle(null)
            setContactPhone(null)

        }catch(e){

            console.e(e)

            Swal.fire({
                title: 'Error',
                text : "An Error Occured",
                icon: 'error',
                confirmButtonText: 'OK'
            })

        }
    }

    return <div>

        <Header
            clearMode={false}
            activeHeader={"sponsorship"}
            loginButton={true}
            joinExpo={() => {
                history.push("/login_")
            }}/>

        <div style={{
            marginTop : 125,
            display : "flex",
            alignItems : "center",
            justifyContent : "center",
            flexDirection : "column",
            marginBottom : 50
        }}>

            <Row
                style={{
                    paddingTop: 50,
                    paddingBottom: 50,
                    // backgroundColor : Palette.IIDEC_RED
                }}
            >
                <Col
                    style={{
                        textAlign: "center"
                    }}
                    md={12}>
                    <h4>
                        <div style={{
                            fontFamily: 'conthrax-bold',
                            marginBottom: 10
                        }}>
                            Sponsorship Opportunities
                        </div>
                    </h4>

                    <Row>
                        <Col md={12}>
                            <p style={{marginTop : 10, padding : 30, textAlign : "center"}}>
                                Sponsoring at IIDEC 2021 provides the key opportunity to network with thousands of industry specialist and showcase your products, services, and solutions to the global market!
                                Grab it fast, the slots are limited!

                                <br/><br/>

                                Please fill in the form below, we will contact you and send the complete package of documents for sponsoring IIDEC 2021.
                            </p>
                        </Col>
                    </Row>

                    <div style={{padding: 30}}>
                        <Form>
                            <Row>
                                <Col
                                    md={6}>
                                    <Form.Group
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start"
                                        }}
                                        className="mb-3" controlId="formBasicEmail">
                                        <Form.Label style={{...styles.boldText}}>Full Name</Form.Label>
                                        <Form.Control
                                            placeholder={"Full Name"}
                                            value={contactFullName ? contactFullName : ""}
                                            onChange={(e) => {
                                                setContactFullName(e.target.value)
                                            }}
                                            style={{...styles.normalText}}/>

                                    </Form.Group>
                                </Col>
                                <Col
                                    md={6}>
                                    <Form.Group
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start"
                                        }}
                                        className="mb-3">
                                        <Form.Label style={{...styles.boldText}}>Company</Form.Label>
                                        <Form.Control
                                            placeholder={"Company"}
                                            value={contactCompany ? contactCompany : ""}
                                            onChange={(e) => {
                                                setContactCompany(e.target.value)
                                            }}
                                            style={{...styles.normalText}}/>

                                    </Form.Group>
                                </Col>
                                <Col
                                    md={6}>
                                    <Form.Group
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start"
                                        }}
                                        className="mb-3">
                                        <Form.Label style={{...styles.boldText}}>Email</Form.Label>
                                        <Form.Control
                                            placeholder={"Email"}
                                            value={contactEmail ? contactEmail : ""}
                                            onChange={(e) => {
                                                setContactEmail(e.target.value)
                                            }}
                                            style={{...styles.normalText}}/>

                                    </Form.Group>
                                </Col>
                                <Col
                                    md={6}>
                                    <Form.Group
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start"
                                        }}
                                        className="mb-3">
                                        <Form.Label style={{...styles.boldText}}>Phone</Form.Label>
                                        <Form.Control
                                            placeholder={"Phone / Whatsapp"}
                                            value={contactPhone ? contactPhone : ""}
                                            onChange={(e) => {
                                                setContactPhone(e.target.value)
                                            }}
                                            style={{...styles.normalText}}/>

                                    </Form.Group>
                                </Col>
                                <Col
                                    md={6}>
                                    <Form.Group
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start"
                                        }}
                                        className="mb-3">
                                        <Form.Label style={{...styles.boldText}}>Job Title</Form.Label>
                                        <Form.Control
                                            placeholder={"Job Title"}
                                            value={contactJobTitle ? contactJobTitle : ""}
                                            onChange={(e) => {
                                                setContactJobTitle(e.target.value)
                                            }}
                                            style={{...styles.normalText}}/>

                                    </Form.Group>
                                </Col>

                                <Col
                                    style={{
                                        display : "flex",
                                        flexDirection : "column",
                                        alignItems : "flex-end"
                                    }}
                                    md={12}>
                                    <CustomButton
                                        onClick={()=>{
                                            contactFormSubmit()
                                        }}
                                    >
                                        Submit
                                    </CustomButton>
                                </Col>

                            </Row>

                        </Form>

                    </div>


                </Col>
            </Row>

        </div>

        <Footer/>

    </div>
}
