import React from "react";
import Button from "react-bootstrap/cjs/Button";

export default function CustomButton(props){
    return <Button
        {...props}
        style={{
            color :"white",
            ...props.style
        }}
    >
        {props.children}
    </Button>
}
