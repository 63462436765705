import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FaFacebook, FaFacebookF, FaKeyboard, FaMapMarkerAlt, FaTwitter} from "react-icons/all";
import {FaEnvelope, FaInstagram, FaWhatsapp, FaYoutube} from "react-icons/all";

import faqPDF from "../assets/FAQ SGU VIRTUAL TOUR .pdf"
import sguWhite from "../assets/sgu-logo-white-300x139.png"
import Modal from "react-bootstrap/Modal";
import Palette from "../utils/Palette";
import lsdLogo from "../assets/VLSD LOGO.png"
import {Link} from "react-router-dom";
import logo from "../assets/logo.png"


export default function Footer(props) {

    const [aboutUsModal, setAboutUsModal] = useState(false)


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])


    const isMobile = windowWidth < 768

    return <>

        <Modal
            show={aboutUsModal}
            size={"xs"}>


            <Modal.Body style={{
                backgroundColor: "white",
                height: 350,
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                padding: 20
            }}
            >

                <b>About Virtual Expo</b>

                <br/>
                Powered By:<b style={{fontFamily: "Spartan", fontWeight: "900", marginTop: 10}}>INTELLIVENT</b>
                <br/>
                Booth and Hall Design By :

                <a href={"https://www.instagram.com/Rayagyra/"}>
                    <img
                        style={{
                            height: 85,
                            marginTop: 15,
                            objectFit: "contain"
                        }}
                        src={lsdLogo}/>

                </a>


                {/*<b style={{fontWeight: "900", marginTop: 10}}>Ray Agra</b>*/}
                <br/>
                {/*<img*/}
                {/*    style={{*/}
                {/*        height: 60,*/}
                {/*        objectFit: "contain"*/}
                {/*    }}*/}
                {/*    src={sguWhite}/>*/}

                <div
                    onClick={() => {
                        setAboutUsModal(false)
                    }}
                    style={{
                        cursor: "pointer",
                        marginTop: 10,
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%"
                    }}>
                    Close
                </div>


            </Modal.Body>

        </Modal>

        <div style={{winWidth: '100vh', backgroundColor: Palette.IIDEC_RED, height: 1}}/>
        <div style={{
            // height: "15em",
            width: "100%",
            backgroundColor: Palette.IIDEC_RED
            // boxShadow: "5px 5px 5px 5px #888888",
        }}>
            <Container fluid style={{
                width: "100%",
                zIndex: 1000,
                color: "white",
                fontSize: "0.9em",
                paddingTop: "1em"
            }}>
                    <Row style={{marginTop: "0.5em", marginBottom: "1em"}}>
                        <Col md={12} style={{display: "flex", flexDirection: "column", alignItems : 'center'}}>
                            <div style={{
                                textAlign : 'center',
                                fontSize : "1.1em"
                            }}>
                                <b style={{fontSize : "1.4em", marginTop: "0.5em", fontFamily: 'conthrax-bold'}}>
                                    IIDEC 2021
                                </b>
                                <br/>
                                <br/>
                                Gedung BJ Habibie,
                                Jl. M.H. Thamrin No.8, <br/>
                                Jakarta 10340
                                <br/>
                                <br/>
                                Telp : +6221 39506872, Whatsapp +62812 13389818

                            </div>
                        </Col>
                    </Row>

                    {/*<Row style={{marginTop: "0.5em", marginBottom: "1em", width: "100%", justifyContent : "center"}}>*/}
                    {/*    <Col md={3} style={{display: "flex", flexDirection: "column", alignItems :isMobile ? 'flex-start' : 'center'}}>*/}
                    {/*        <div>*/}
                    {/*            <b style={{marginTop: "0.5em", fontFamily: 'conthrax-bold'}}>*/}
                    {/*                IIDEC 2021*/}
                    {/*            </b>*/}
                    {/*            <ul style={{paddingLeft: "0.1em", marginTop: "1em"}}>*/}

                    {/*                Gedung BJ Habibie,<br/>*/}
                    {/*                Jl. M.H. Thamrin No.8, <br/>*/}
                    {/*                Jakarta 10340*/}

                    {/*            </ul>*/}
                    {/*        </div>*/}

                    {/*    </Col>*/}
                    {/*    <Col md={3} style={{display: "flex", flexDirection: "column", alignItems : isMobile ? 'flex-start' : "center"}}>*/}

                    {/*        <div>*/}

                    {/*            <b style={{marginTop: "0.5em", fontFamily: 'conthrax-bold'}}>*/}
                    {/*                Phone :*/}
                    {/*            </b>*/}

                    {/*            <div style={{paddingLeft: "0.1em", marginTop: "1em"}}>*/}
                    {/*                +6221 39506872*/}
                    {/*            </div>*/}

                    {/*        </div>*/}

                    {/*    </Col>*/}

                    {/*    /!*<Col*!/*/}
                    {/*    /!*    style={{*!/*/}
                    {/*    /!*        display: "flex",*!/*/}
                    {/*    /!*        alignItems: "center",*!/*/}
                    {/*    /!*        justifyContent: "center",*!/*/}
                    {/*    /!*        marginTop : isMobile ? "2em" : 0*!/*/}
                    {/*    /!*    }}*!/*/}
                    {/*    /!*    md={6}*!/*/}
                    {/*    /!*>*!/*/}
                    {/*    /!*    <div style={{*!/*/}
                    {/*    /!*        background: "white",*!/*/}
                    {/*    /!*        borderRadius: 20,*!/*/}
                    {/*    /!*        width: "80%"*!/*/}
                    {/*    /!*    }}>*!/*/}
                    {/*    /!*        <img*!/*/}
                    {/*    /!*            style={{*!/*/}
                    {/*    /!*                width: "100%",*!/*/}
                    {/*    /!*                paddingLeft: "5%",*!/*/}
                    {/*    /!*                paddingRight: "5%",*!/*/}
                    {/*    /!*            }}*!/*/}
                    {/*    /!*            src={logo}/>*!/*/}
                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</Col>*!/*/}

                    {/*</Row>*/}
                    {/*<Row style={{width: "100%", justifyContent : "center"}}>*/}
                    {/*    <Col md={3} style={{display: "flex", flexDirection: "column", alignItems :isMobile ? 'flex-start' : 'center'}}>*/}
                    {/*        <div>*/}
                    {/*            <b style={{marginTop: isMobile ? "3em" : "1.8em", fontFamily: 'conthrax-bold'}}>*/}
                    {/*                Email :*/}
                    {/*            </b>*/}

                    {/*            <div style={{paddingLeft: "0.1em", marginTop: "0.5em"}}>*/}
                    {/*                info@iidec.id<br/>*/}
                    {/*                <div style={{color : Palette.IIDEC_RED}}>*/}
                    {/*                    <FaMapMarkerAlt style={{color: Palette.IIDEC_RED, marginRight: "0.5em"}}/>*/}

                    {/*                    Gedung BJ Habibie,<br/>*/}
                    {/*                    Jl. M.H. Thamrin No.8, <br/>*/}
                    {/*                    Jakarta 10340*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col md={3} style={{display: "flex", flexDirection: "column", alignItems : isMobile ? 'flex-start' : "center"}}>*/}

                    {/*        <div>*/}
                    {/*            <b style={{marginTop: isMobile ? "3em" : "1.8em", fontFamily: 'conthrax-bold'}}>*/}
                    {/*                Whatsapp :*/}
                    {/*            </b>*/}

                    {/*            <div style={{paddingLeft: "0.1em", marginTop : "0.5em"}}>*/}
                    {/*                +62812 13389818*/}
                    {/*            </div>*/}

                    {/*        </div>*/}

                    {/*    </Col>*/}

                    {/*</Row>*/}

                <Row>
                    <Col
                        md={12}
                        style={{
                            backgroundColor: Palette.IIDEC_RED, display: "flex",
                            alignItems: "center", color: "white",
                            justifyContent: "center",
                            paddingTop: 8, paddingBottom: 8,
                            flexDirection: "column"
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <a
                                style={{
                                    background : "white",
                                    display : "flex",
                                    alignItems : 'center',
                                    justifyContent : 'center',
                                    borderRadius : "2em",
                                    width : "2.5em",
                                    height :"2.5em"
                                }}
                                className={"red-link"}
                                href={"https://www.instagram.com/iidec.event/"} target="_blank">
                                <FaInstagram style={{color: Palette.IIDEC_RED, fontSize : "1.2em"}}/>
                            </a>

                            <a
                                style={{
                                    background : "white",
                                    display : "flex",
                                    alignItems : 'center',
                                    justifyContent : 'center',
                                    borderRadius : "2em",
                                    width : "2.5em",
                                    height :"2.5em",
                                    marginLeft : "1em"
                                }}
                                className={"red-link"}
                                href={"https://www.facebook.com/Indonesia-International-Disaster-Expo-Conference-113030871192347"}
                                target="_blank">
                                <FaFacebookF style={{color: Palette.IIDEC_RED, fontSize : "1.2em"}}/>
                            </a>
                        </div>
                        <div style={{marginTop : 10}}>
                            © Copyright&nbsp;<b>BRIN</b>&nbsp;2021
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    </>
}
