import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import React, {useState} from "react";
import Header from "./components/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import banner from "./assets/banner.jpeg"
import Card from "react-bootstrap/Card";
import {FaUserGraduate} from "react-icons/all";
import styles from "./values/styles";
import {FiFacebook, FiInstagram, FiTwitter, FiYoutube} from "react-icons/fi";
import {IoDocumentTextOutline} from "react-icons/io5";
import {FaChalkboardTeacher, FaWhatsapp} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {HiOutlineDesktopComputer} from "react-icons/hi";
import {RiWhatsappLine} from "react-icons/ri";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {
    isMobile
} from "react-device-detect";
import 'react-ig-feed/dist/index.css'

import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom"
import LandingPage from "./components/LandingPage";
import UnityPage from "./components/UnityPage";
import CustomButton from "./components/Reusable/CustomButton";
import Swal from "sweetalert2";
import ConferenceAgendaPage from "./components/ConferenceAgendaPage";
import SponsorshipPage from "./components/SponsorshipPage";

import ReactGA from 'react-ga';

ReactGA.initialize('UA-213169882-1', {
    debug: true,
    titleCase: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(false)

    return (
        <Container fluid style={{
            padding: 0,
            overflowX: "hidden"
        }}>
            <a href={"https://wa.me/+6281213389818"} target="_blank">
                <div
                    style={{
                        cursor: 'pointer',
                        position: 'fixed',
                        zIndex: 9999999,
                        bottom: 25,
                        right: 25,
                        backgroundColor: '#26d367',
                        width: 35,
                        height: 35,
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: 'rgb(136, 136, 136) 0px 0px 3px 1px',
                    }}>
                    <RiWhatsappLine color={'white'} size={25}/>
                </div>
            </a>


            <Modal show={isRegisterShown} onHide={() => setRegisterShown(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{...styles.boldText}}>Visitor Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                            <Form.Control type="email" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{...styles.boldText}}>Phone Number :</Form.Label>
                            <Form.Control type="tel" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Full Name : </Form.Label>
                            <Form.Control type="text" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Password : </Form.Label>
                            <Form.Control type="password" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Password Confirmation : </Form.Label>
                            <Form.Control type="password" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>School : </Form.Label>
                            <Form.Control type="text" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Grade : </Form.Label>
                            <Form.Select>
                                <option disabled={true}>Select Grade . . .</option>
                                <option>Parent</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Study Program Interest : </Form.Label>
                            <Form.Select>
                                <option disabled={true}>Select Study Program Interest . . .</option>
                                <option>Accounting and Data Analytics</option>
                                <option>Biomedical Engineering</option>
                                <option>Business and Management</option>
                                <option>Food Technology</option>
                                <option>Global Strategic Communication</option>
                                <option>Hotel and Tourism Management</option>
                                <option>Industrial Engineering</option>
                                <option>Information Technology</option>
                                <option>International Culinary Business</option>
                                <option>Master of Arts in Business</option>
                                <option>Master of Business Administration</option>
                                <option>Master of Information Technology</option>
                                <option>Master of Mechanical Engineering</option>
                                <option>Mechatronics Engineering</option>
                                <option>Pharmaceutical Chemical Engineering</option>
                                <option>Sustainable Energy and Environment</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>

                    <div style={{...styles.normalText}}>
                        Already have an account? <a href={'#'}
                                                    onClick={() => {
                                                        setLoginShown(true)
                                                        setRegisterShown(false)
                                                    }}>Login</a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            style={{...styles.boldText}}
                            onClick={() => setRegisterShown(false)}>
                        Close
                    </Button>
                    <CustomButton variant="primary"
                                  style={{...styles.boldText}}
                                  onClick={() => {
                                  }}>
                        Login
                    </CustomButton>
                </Modal.Footer>
            </Modal>

            <Modal show={isLoginShown} onHide={() => setLoginShown(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{...styles.boldText}}>Visitor Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                            <Form.Control type="tel" style={{...styles.normalText}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label style={{...styles.boldText}}>Password :</Form.Label>
                            <Form.Control type="password" style={{...styles.normalText}}/>
                        </Form.Group>
                    </Form>

                    <div style={{...styles.normalText}}>
                        Don't have an account? <a href={'#'}
                                                  onClick={() => {
                                                      setLoginShown(false)
                                                      setRegisterShown(true)
                                                  }}>Sign up</a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                            style={{...styles.boldText}}
                            onClick={() => setLoginShown(false)}>
                        Close
                    </Button>

                    <a href={'https://expodemo.intellivent.id/sgu-open-house'}>
                        <Button variant="primary"
                                style={{...styles.boldText}}
                                onClick={() => {
                                }}>
                            Login
                        </Button>
                    </a>
                </Modal.Footer>
            </Modal>

            <HashRouter>
            {/*<BrowserRouter>*/}
                <Switch>
                    <Route exact path={"/"} component={LandingPage}/>
                    <Route exact path={"/login"} render={(props) => {
                        return (
                            <LandingPage
                                {...props}
                                openLogin={() => {
                                    setLoginShown(true)
                                }}
                            />
                        )
                    }}/>

                    <Route exact path={"/login_"} render={(props) => {
                        return (
                            <LandingPage
                                {...props}
                                openLogin={() => {
                                    setLoginShown(true)
                                }}
                            />
                        )
                    }}/>

                    <Route exact path={"/conference-agenda"} component={ConferenceAgendaPage}/>
                    <Route exact path={"/sponsorship"} component={SponsorshipPage}/>

                    <Route exact path={"/account-activated"} render={(props) => {
                        return (
                            <LandingPage
                                {...props}
                                openLogin={() => {
                                    Swal.fire({
                                        title: 'Your account has been activated successfully.',
                                        icon: 'success',
                                    })
                                    setLoginShown(true)
                                }}
                            />
                        )
                    }}/>

                    <Route exact path={"/activation-expired"} render={(props) => {
                        return (
                            <LandingPage
                                {...props}
                            />
                        )
                    }}/>

                    <Route exact path={"/register"} render={(props) => {
                        return (
                            <LandingPage
                                {...props}
                                isRegisterShown={true}
                                openLogin={() => {
                                    setLoginShown(true)
                                }}
                            />
                        )
                    }}/>

                    <Route exact path={"/expo"} component={UnityPage}/>
                </Switch>
            {/*</BrowserRouter>*/}
            </HashRouter>


        </Container>
    );

}

export default App;
