import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../assets/logo.png"
import Button from "react-bootstrap/Button";
import styles from "../values/styles";
import { Link } from "react-router-dom";
import CustomButton from "./Reusable/CustomButton";
import { scroller } from 'react-scroll'
import Swal from "sweetalert2";
import Palette from "../utils/Palette";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaDoorOpen } from "react-icons/fa";
function Header(props) {
    const {
        joinExpo, loginButton, entranceTestButton, mainMenu, activeHeader,
        clearMode
    } = props

    // console.log('activeHeader', activeHeader)

    const scrollTo = (to) => {
        scroller.scrollTo(to, {
            duration: 100,
            delay: 0,
            smooth: true,
            offset: -100
        })
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [navToogle, setNavToogle] = useState(false)

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const logoutButton = () => {
        return (localStorage.getItem('token') &&
            <CustomButton
                onClick={() => {

                    localStorage.removeItem('token')
                    localStorage.removeItem('gender')
                    localStorage.removeItem('fullname')
                    window.location = "/"
                }}
                style={{
                    ...styles.boldText,
                    width: 120,
                    marginLeft: 5
                }}
                size={"sm"}>
                Logout
            </CustomButton>)
    }

    return (
        <Container fluid style={{
            position: 'fixed', top: 0, zIndex: 101,
            width: "100%",
        }}>
            <Row style={{
                boxShadow: clearMode ? null : 'rgb(136, 136, 136) 0px -2px 3px 1px',
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                backgroundColor: clearMode ? null : 'white',
                // height: 76,
                padding: 5
            }}>
                <Col xs={6} md={3} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start'
                }}
                >
                    <a
                        style={{
                            // flex: 1,
                            // display: "flex",
                            alignItems: "flex-start"
                        }}
                        href={"/"}>

                        <div style={{
                            padding: 5,
                            background: "white",
                            borderRadius: 10
                        }}>
                            <img
                                src={logo}
                                style={{
                                    width: windowWidth < 640 ? '170px' : '200px',
                                    height: 90,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    flex: 1,
                                    objectFit: "contain"
                                }} />
                        </div>


                    </a>
                </Col>
                <Col xs={6} md={9} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: "flex-end",
                    marginBottom: 20
                }}
                >
                    {
                        windowWidth > 768 &&
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: 50,
                        }}
                        >
                            <Link
                                to={"/"}
                                className={clearMode ? "white-link" : "red-link"}
                                style={{
                                    fontWeight: activeHeader === 'home' ? '600' : '400'
                                }}>
                                Home
                                {
                                    activeHeader === 'home' &&
                                    <div style={{
                                        width: "100%",
                                        height: 3,
                                        backgroundColor: clearMode ? "white" : Palette.IIDEC_RED
                                    }}>

                                    </div>
                                }

                            </Link>
                            <Link
                                to={"/conference-agenda"}
                                className={clearMode ? "white-link" : "red-link"}
                                style={{
                                    marginLeft: 20,
                                    fontWeight: activeHeader === 'conference-agenda' ? '600' : '400'
                                }}>
                                Conference Agenda
                                {
                                    activeHeader === 'conference-agenda' &&
                                    <div style={{
                                        width: "100%",
                                        height: 3,
                                        backgroundColor: clearMode ? "white" : Palette.IIDEC_RED
                                    }}>

                                    </div>
                                }

                            </Link>
                            <Link
                                to={"/sponsorship"}
                                className={clearMode ? "white-link" : "red-link"}
                                style={{
                                    marginLeft: 20,
                                    fontWeight: activeHeader === 'sponsorship' ? '600' : '400'
                                }}>
                                Sponsorship
                                {
                                    activeHeader === 'sponsorship' &&
                                    <div style={{
                                        width: "100%",
                                        height: 3,
                                        backgroundColor: clearMode ? "white" : Palette.IIDEC_RED
                                    }}>

                                    </div>
                                }

                            </Link>

                        </div>

                    }



                    {/*{*/}
                    {/*    loginButton &&*/}
                    {/*    <CustomButton*/}
                    {/*        style={{*/}
                    {/*            ...styles.boldText,*/}
                    {/*            width: windowWidth < 768 ? 100 : 250,*/}

                    {/*        }}*/}
                    {/*        onClick={joinExpo}*/}
                    {/*        size={"sm"}>*/}
                    {/*        {localStorage.getItem('token') ? 'Join Expo' : windowWidth < 768 ? "Register" : "Visitor Login"}*/}
                    {/*    </CustomButton>*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    entranceTestButton &&*/}
                    {/*    <a href={"https://entrancetest.sgu.ac.id/"}>*/}
                    {/*        <CustomButton*/}
                    {/*            style={{*/}
                    {/*                ...styles.boldText,*/}
                    {/*                width: 120,*/}
                    {/*            }}*/}
                    {/*            size={"sm"}>*/}
                    {/*            Join Expo*/}
                    {/*        </CustomButton>*/}
                    {/*    </a>*/}
                    {/*}*/}


                    {/*{*/}
                    {/*    mainMenu && <a href={"https://zoom.us/j/95910779098?pwd=ckh0ODNnVWZNOUR4TG4vdGZPMERHZz09"}>*/}
                    {/*        <CustomButton*/}
                    {/*            style={{*/}
                    {/*                ...styles.boldText,*/}
                    {/*                width: 120,*/}
                    {/*            }}*/}
                    {/*            size={"sm"}>*/}
                    {/*            Join Expo*/}
                    {/*        </CustomButton>*/}
                    {/*    </a>*/}
                    {/*}*/}

                    {/*{*/}
                    {/*    windowWidth > 768 &&*/}
                    {/*    logoutButton()*/}
                    {/*}*/}
                    {
                        windowWidth < 768 &&
                        <Button onClick={() => {
                            setNavToogle(!navToogle)
                        }} style={{ 'position': 'relative', 'left': '10px' }} variant={'light'}><GiHamburgerMenu></GiHamburgerMenu></Button>
                    }
                </Col>
                {
                    navToogle && windowWidth < 768 &&
                    <div style={{
                        display: "block",
                        marginRight: 50,
                    }}
                    >
                        <div className={'row-block'}>
                            <Link
                                to={"/"}
                                className={clearMode ? "white-link" : "red-link"}
                                style={{
                                    fontWeight: activeHeader === 'home' ? '600' : '400'
                                }}>
                                Home
                                {
                                    activeHeader === 'home' &&
                                    <div style={{
                                        width: "100%",
                                        height: 3,
                                        backgroundColor: clearMode ? "white" : Palette.IIDEC_RED
                                    }}>

                                    </div>
                                }

                            </Link>
                        </div>
                        <div className={'row-block'}>
                            <Link
                                to={"/conference-agenda"}
                                className={clearMode ? "white-link" : "red-link"}
                                style={{
                                    marginLeft: 20,
                                    fontWeight: activeHeader === 'conference-agenda' ? '600' : '400'
                                }}>
                                Conference Agenda
                                {
                                    activeHeader === 'conference-agenda' &&
                                    <div style={{
                                        width: "100%",
                                        height: 3,
                                        backgroundColor: clearMode ? "white" : Palette.IIDEC_RED
                                    }}>

                                    </div>
                                }

                            </Link>
                        </div>
                        <div className={'row-block'}>
                            <Link
                                to={"/sponsorship"}
                                className={clearMode ? "white-link" : "red-link"}
                                style={{
                                    marginLeft: 20,
                                    fontWeight: activeHeader === 'sponsorship' ? '600' : '400'
                                }}>
                                Sponsorship
                                {
                                    activeHeader === 'sponsorship' &&
                                    <div style={{
                                        width: "100%",
                                        height: 3,
                                        backgroundColor: clearMode ? "white" : Palette.IIDEC_RED
                                    }}>

                                    </div>
                                }

                            </Link>
                        </div>
                        <div className={'row-block'}>
                            {logoutButton()}
                        </div>
                    </div>
                }
            </Row>

        </Container>
    );
}

export default Header;
