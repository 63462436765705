import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import styles from "../values/styles";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, {useEffect, useRef, useState} from "react";
import {
    AiOutlinePlayCircle, BsChevronDown, BsMegaphoneFill, BsPlayCircleFill,
    FaChalkboardTeacher, FaMedal, FaRegNewspaper, FaUsers,
    FiInstagram,
    FiYoutube, GiPartyPopper, GiPresent,
    HiOutlineDesktopComputer,
    IoDocumentTextOutline, IoMdTrophy
} from "react-icons/all";
import Header from "./Header";
import User from "../models/User";
import Spinner from "react-bootstrap/Spinner";
import Swal from 'sweetalert2'
import {useHistory} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from "react-responsive-carousel";

import ReactPlayer from "react-player";
import {Card} from "react-bootstrap"

import banner0 from "../assets/IIDEC_WEB_BANNER_POLOS.jpg"
import aboutUsImage from "../assets/wildfire001.jpg"

import sponsor1 from "../assets/dab.jpeg"
import sponsor2 from "../assets/datascript.jpg"
import sponsor3 from "../assets/ccsi.jpeg"
import organizer from "../assets/naganaya.jpeg"

import featureImages from "../assets/orange_innovate.png"

import Palette from "../utils/Palette";
import Footer from "./Footer";

import InstagramFeed from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

import qs from 'qs'

import moment from "moment"
import CustomButton from "./Reusable/CustomButton";
import logo from "../assets/logo.png";
import {HiOutlineBriefcase, HiOutlineMail, HiOutlinePhone} from "react-icons/hi";
import ReactCodeInput from "react-code-input";

import virtualConferenceImage from "../assets/Foto Virtual conference.png"
import virtualExhibitionImage from "../assets/Foto Expo.png"

import logoBrin from "../assets/logo-brin.png"

import {Link, Element, Events, animateScroll as scroll, scrollSpy, scroller} from 'react-scroll'
import Forms from "../models/Forms";
import Util from "../utils/Util";

let requestCountDownTime = 0

export default function LandingPage(props) {

    const homeRef = useRef(null)
    const conferenceAgendaRef = useRef(null)
    const sponsorshipRef = useRef(null)

    const [activeHeader, setActiveHeader] = useState('home')
    const containerRef = useRef(null);

    const history = useHistory();
    const userModel = new User();
    const [loginCreds, setLoginCreds] = useState({});
    const [registerCreds, setRegisterCreds] = useState({});
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(props.isRegisterShown)
    const [isForgotPasswordShown, setForgotPasswordShown] = useState(false)
    const [errors, setErrors] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [profile, setProfile] = useState({});
    const [isPlayingVideo, setIsPlayingVideo] = useState(false)

    const [competitionModal, setCompetitionModal] = useState(false)
    const [doorPrizeModal, setDoorPrizeModal] = useState(false)

    const [daysLeft, setDaysLeft] = useState(null)
    const [hourLeft, setHourLeft] = useState(null)
    const [minuteLeft, setMinuteLeft] = useState(null)
    const [secondLeft, setSecondLeft] = useState(null)

    const [forgotPasswordCreds, setForgotPasswordCreds] = useState({});

    const [isResetPasswordShown, setResetPasswordShown] = useState(false)
    const [resetPasswordCreds, setResetPasswordCreds] = useState(false)

    const [remainingTime, setRemainingTime] = useState(0)
    const [clientHeight, setClientHeight] = useState(window.innerHeight)

    const cardRef = useRef(null);

    const [clearHeader, setClearHeader] = useState(true)

    const [contactFullName, setContactFullName] = useState(null)
    const [contactCompany, setContactCompany] = useState(null)
    const [contactEmail, setContactEmail] = useState(null)
    const [contactPhone, setContactPhone] = useState(null)
    const [contactSubject, setContactSubject] = useState(null)
    const [contactMessage, setContactMessage] = useState(null)

    const iFeedRef = useRef(null)

    // console.log("ifeedref", iFeedRef?.current?.state?.feeds?.length)

    let instagramLength = iFeedRef?.current?.state?.feeds?.length ? iFeedRef?.current?.state?.feeds?.length : 0

    const handleSetActive = (to) => {
        console.log('handleSet', to);
    }

    let cardMargin = 0;

    if (cardRef) {
        cardMargin = cardRef?.current?.clientHeight / 2
    }

    useEffect(() => {
        console.log('activeHeader', activeHeader)
    }, [activeHeader])

    const onScroll = () => {
        const scrolled = window.scrollY
        const pageHeight = document.documentElement.scrollHeight

        // console.log('scrolled (1)', scrolled)
        // console.log('scrolled (2)', conferenceAgendaRef.current)
        // console.log('scrolled (3)', conferenceAgendaRef.current.clientHeight)

        if (scrolled < 200) {
            setClearHeader(true)
        } else {
            setClearHeader(false)
        }

        if (scrolled < homeRef.current.clientHeight) {
            setActiveHeader('home')
        } else if (scrolled >= conferenceAgendaRef.current.offsetTop - 500 && scrolled <= conferenceAgendaRef.current.offsetTop + conferenceAgendaRef.current.clientHeight - 200) {
            setActiveHeader('conference-agenda')
        } else if (scrolled >= pageHeight * .8) {
            setActiveHeader('sponsorship')
        } else {
            setActiveHeader(null)
        }

    }

    useEffect(() => {
        console.log('height height', containerRef.current.clientHeight)
        setClientHeight(containerRef.current.clientHeight)

        window.addEventListener('scroll', onScroll)

        Events.scrollEvent.register('begin', function (to, element) {
            console.log('scroll begin', to, element);
        });

        Events.scrollEvent.register('end', function (to, element) {
            console.log('scroll end', to, element);
        });

        scrollSpy.update();

        let countDownTimeSetInterval = setInterval(function () {

            if (requestCountDownTime > 0) {
                requestCountDownTime--
            }
            setRemainingTime(requestCountDownTime)
        }, 1000);
        return () => {
            window.removeEventListener('scroll', onScroll)
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
            clearInterval(countDownTimeSetInterval)
        }
    }, [])


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        console.log('height height', containerRef.current.clientHeight)
        setClientHeight(containerRef.current.clientHeight)
        setWindowWidth(window.innerWidth)
    }

    const isMobile = windowWidth < 768

    const getProfile = async () => {
        try {
            const profileTemp = await userModel.getMyProfile()

            console.log('profileTemp', profileTemp)

            setProfile(profileTemp)
        } catch (e) {
            console.log(e)
        }
    }

    const attemptForgotPassword = async () => {
        const errorsTemp = {}

        if (!forgotPasswordCreds.email) {
            errorsTemp.email = 'Please fill out this field.'
        } else {
            setLoading(true)

            try {
                const response = await userModel.forgotPassword(forgotPasswordCreds)

                setResetPasswordShown(true)
                setForgotPasswordShown(false)

                setRemainingTime(90)
                requestCountDownTime = 90
            } catch (e) {
                if (e.code === 'EMAIL NOT FOUND_NOT_FOUND') {
                    errorsTemp.email = 'Email is not registered'
                }

                console.log(e)
            }
        }

        setLoading(false)
        setErrors(errorsTemp)
    }

    const attemptResetPassword = async () => {
        const errorsTemp = {};

        if (!resetPasswordCreds.token) {
            errorsTemp.verification_code = 'Please fill out this field.'
        }

        if (!resetPasswordCreds.new_password) {
            errorsTemp.password = 'Please fill out this field.'
        }

        if (!resetPasswordCreds.confirm_password) {
            errorsTemp.confirm_password = 'Please fill out this field.'
        }

        if (resetPasswordCreds.new_password !== resetPasswordCreds.confirm_password) {
            errorsTemp.confirm_password = 'Password and confirm password does not match.'
        }

        if (Object.keys(errorsTemp).length === 0) {
            try {
                const response = await userModel.resetPassword({
                    ...resetPasswordCreds,
                    email: forgotPasswordCreds.email
                })

                Swal.fire({
                    title: 'Password has been updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })

                console.log(response)

                setResetPasswordShown(false)
                setForgotPasswordShown(false)
            } catch (e) {
                if (e.code === 'TOKEN TIDAK DITEMUKAN_NOT_FOUND') {
                    errorsTemp.verification_code = 'Incorrect verification code.'
                }
                console.log(e)
            }
        }

        setErrors(errorsTemp)
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getProfile()
        }

        console.log(localStorage.getItem('token'))

        if (props.location.pathname === '/account-activated' && !localStorage.getItem('token')) {
            alert('Your account has been activated successfully.')
            setLoginShown(true)
        }

        if (props.location.pathname === '/activation-expired' && !localStorage.getItem('token')) {

            let email = qs.parse(props.location.search, {ignoreQueryPrefix: true}).email

            Swal.fire({
                title: 'Your activation link has expired. You can renew your activation link by pressing the button below',
                icon: 'warning',
                confirmButtonText: 'Resend Email',
                cancelButtonText: 'Close',
                showCancelButton: true
            }).then(async (result) => {
                console.log("Rees email", result, email, decodeURIComponent(email))
                console.log(email)
                console.log(decodeURIComponent(email))
                if (result.isConfirmed) {
                    try {
                        await userModel.resendEmailValidation({
                            email: email
                        })

                        Swal.fire({
                            title: 'Verification email has been sent successfully.',
                            icon: 'success',
                        })
                    } catch (e) {

                        Swal.fire({
                            title: 'Internal Server Error',
                            text: "Please contact Support",
                            icon: 'error',
                        })

                        console.log(e)
                    }
                }
            })
        }

        if (props.match.url === '/login_' && !localStorage.getItem('token')) {
            setLoginShown(true)
        }

        if (props.match.url === '/login' && !localStorage.getItem('token')) {
            Swal.fire({
                title: 'Please login before entering this page',
                icon: 'info',
                confirmButtonText: 'OK'
            }).then((result) => {
                setLoginShown(true)
            })
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        let timer = setInterval(() => {
            console.log()
            let dayDiff = moment().diff("2021-12-14 07:00", "days");
            let hourDiff = moment().diff("2021-12-14 07:00", "hour");
            let minuteDiff = moment().diff("2021-12-14 07:00", "minute");
            let secondDiff = moment().diff("2021-12-14 07:00", "second");

            if (dayDiff <= 0) {
                setDaysLeft(dayDiff * -1)
            }

            if (hourDiff <= 0) {
                setHourLeft(hourDiff * -1 % 24)
            }

            if (minuteDiff <= 0) {
                setMinuteLeft(minuteDiff * -1 % 60)
            }

            if (secondDiff <= 0) {
                setSecondLeft(secondDiff * -1 % 60)
            }

        }, 1000)

        return () => clearInterval(timer)

    }, [])

    useEffect(() => {
        if (!isLoginShown || !isRegisterShown) {
            setLoginCreds({})
            setRegisterCreds({})
            setErrors({})
            setLoading(false)
        }
    }, [isLoginShown, isRegisterShown])

    const test = () => {
        alert('test')
    }

    const attemptLogin = async () => {
        const errorsTemp = {}

        if (!loginCreds.email) {
            errorsTemp.email = 'Please fill out this field.'
        }

        if (!loginCreds.password) {
            errorsTemp.password = 'Please fill out this field.'
        }

        if (Object.keys(errorsTemp).length === 0) {
            setLoading(true)
            const {email, password} = loginCreds;

            try {
                const response = await userModel.login({
                    email,
                    password
                })

                console.log("logindata", response)

                setProfile(response)

                localStorage.setItem('token', response.token)
                localStorage.setItem('gender', response.gender)
                localStorage.setItem('fullname', response.fullname)

                history.push('/expo')

                console.log(response)

                setLoading(false)
            } catch (e) {
                if (e.code === 'EMAIL_NOT_FOUND' || e.code === 'USER_PASSWORD_WRONG') {
                    errorsTemp.form = 'Invalid Credential.'
                } else if (e.error_message === "ACCOUNT_UNVERIFIED") {
                    Swal.fire({
                        title: 'Your account is not yet activated. Please check your email to activate your account.',
                        icon: 'warning',
                        confirmButtonText: 'Resend Email',
                        cancelButtonText: 'Close',
                        showCancelButton: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            try {
                                await userModel.resendEmailValidation({
                                    email: loginCreds.email
                                })

                                Swal.fire({
                                    title: 'Verification email has been sent successfully.',
                                    icon: 'success',
                                })
                            } catch (e) {
                                Swal.fire({
                                    title: 'Internal Server Error',
                                    text: "Please contact Support",
                                    icon: 'error',
                                })
                            }
                        }

                        setLoginShown(true)
                    })
                } else {
                    errorsTemp.form = 'An error occurred.'
                }

                setLoading(false)
            }
        }

        setErrors(errorsTemp)
    }

    const attemptRegister = async (e) => {
        const errorsTemp = {}

        if (!registerCreds.email) {
            errorsTemp.email = 'Please fill out this field.'
        }

        if (!registerCreds.phone_num) {
            errorsTemp.phone_num = 'Please fill out this field.'
        } else if (!registerCreds.phone_num.includes("+")) {
            errorsTemp.phone_num = 'Incorrect format'
        }


        if (!registerCreds.fullname) {
            errorsTemp.fullname = 'Please fill out this field.'
        }

        if (!registerCreds.gender) {
            errorsTemp.gender = 'Please fill out this field.'
        }

        if (registerCreds.password !== registerCreds.confirm_password) {
            errorsTemp.confirm_password = 'Password and confirm password does not match.'
        }

        if (!registerCreds.password) {
            errorsTemp.password = 'Please fill out this field.'
        }

        if (!registerCreds.confirm_password) {
            errorsTemp.confirm_password = 'Please fill out this field.'
        }

        if (!registerCreds.job) {
            errorsTemp.job = 'Please fill out this field.'
        }

        if (!registerCreds.industry) {
            errorsTemp.industry = 'Please select an item in the list.'
        }

        if (Object.keys(errorsTemp).length === 0) {
            setLoading(true)

            try {
                const {email, phone_num, fullname, password, job, industry, gender} = registerCreds;

                const response = await userModel.register({
                    email,
                    phone_num,
                    fullname,
                    password,
                    gender,
                    job,
                    industry
                })

                Swal.fire({
                    title: 'You are successfully registered!',
                    text: 'Please check your email to activate your account.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    setRegisterShown(false)
                    setLoginShown(true)
                })
            } catch (e) {
                console.log(e)
                if (e.code === 'DUPLICATE_EMAIL') {
                    errorsTemp.email = 'Email is already registered.'
                } else {
                    errorsTemp.form = 'An error occurred.'
                }
            }
        }

        setLoading(false)
        setErrors(errorsTemp)
    }

    const joinExpo = () => {
        if (localStorage.getItem('token')) {
            history.push('/expo')
        } else {
            setLoginShown(true)
        }
    }

    const contactFormSubmit = async () => {
        try {
            let forms = new Forms()

            if (!contactFullName) {
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text: "Fullname cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if (!contactEmail) {
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text: "Email cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if (!Util.validateEmail(contactEmail)) {
                return Swal.fire({
                    title: 'Please check your entry',
                    text: "Invalid Email",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if (!contactSubject) {
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text: "Subject cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            if (!contactMessage) {
                return Swal.fire({
                    title: 'Please fill in necessary fields',
                    text: "Message cannot be empty",
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }

            let params = {
                email: contactEmail,
                full_name: contactFullName,
                company: contactCompany,
                subject: contactSubject,
                phone_num: contactPhone,
                message: contactMessage
            }

            await forms.postContactUsForm(params)

            Swal.fire({
                title: 'Your form has been submitted',
                text: "Thank you for your participation",
                icon: 'success',
                confirmButtonText: 'OK'
            })

            setContactEmail(null)
            setContactFullName(null)
            setContactCompany(null)
            setContactSubject(null)
            setContactPhone(null)
            setContactMessage(null)

        } catch (e) {

            console.e(e)

            Swal.fire({
                title: 'Error',
                text: "An Error Occured",
                icon: 'error',
                confirmButtonText: 'OK'
            })

        }
    }

    return <div ref={containerRef}>
        <Header
            // clearMode={clearHeader}
            activeHeader={"home"}
            loginButton={true}
            joinExpo={joinExpo}/>

        {/*<Row style={{*/}
        {/*    marginTop: 70*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12}>*/}
        {/*        <img*/}
        {/*            style={{*/}
        {/*                width: "100%",*/}
        {/*                objectFit: "contain"*/}
        {/*            }}*/}
        {/*            src={banner}/>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <div
            style={{
                marginTop: 100
            }}
            ref={homeRef}>
            <Element name="home" className="element">
                <Row style={{
                    // width: "100%",
                    padding: 0,
                    margin: 0,
                    background: `url(${banner0})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center-top",
                    minHeight: "70vh"
                }}
                >
                    {
                        <Col
                            style={{
                                width: "100%",
                                padding: 0,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                fontFamily: "conthrax-bold",
                                color: "white"
                            }}
                            md={12}
                        >


                            {
                                !isMobile && <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    fontWeight: "bold",
                                    fontFamily: " \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", \"Liberation Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\""
                                }}
                                >

                                    <div style={{
                                        backgroundColor: "#373d4b",
                                        borderRadius: "0 0 20px 20px",
                                        // height : 150,
                                        // width : 200,
                                        padding: 20,
                                        marginRight: 50,
                                        textAlign: "center"
                                    }}>
                                        VIRTUAL EXHIBITION<br/>
                                        8-15 DECEMBER 2021
                                    </div>

                                    <div style={{
                                        backgroundColor: "#373d4b",
                                        borderRadius: "0 0 20px 20px",
                                        // height : 150,
                                        // width : 200,
                                        padding: 20,
                                        marginRight: 20,
                                        textAlign: "center"
                                    }}>
                                        VIRTUAL CONFERENCE<br/>
                                        14-15 DECEMBER 2021
                                    </div>

                                </div
                                >
                            }

                            <p style={{
                                marginTop: !isMobile ? 50 : 100,
                                alignSelf: "flex-start",
                                paddingLeft: isMobile ? 20 : 110,
                                fontSize: "1.5em"
                            }}
                            >
                                <div style={{
                                    fontSize: isMobile ? "1.3em" : "2.2em",
                                    lineHeight: "1.1em",
                                }}>
                                    INDONESIA<br/>
                                    INTERNATIONAL<br/>
                                    <div style={{fontSize: "1.2em"}}>DISASTER</div>
                                </div>
                                {/*<br/>*/}
                                <div style={{
                                    fontSize: "1.75em"
                                }}>
                                    EXPO & CONFERENCE
                                </div>
                                <br/><br/>
                                <div style={{fontSize: "1.2em"}}>
                                    See you at IIDEC 2022
                                </div>

                            </p>

                            {
                                !isMobile &&
                                <div style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                    marginRight: 50
                                }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "white",
                                            zIndex: 100,
                                            borderRadius: 10,
                                            color: Palette.IIDEC_RED,
                                            fontFamily: 'conthrax-bold',
                                            marginBottom: 10
                                        }}
                                    >
                                        {/*<div style={{textAlign: "center"}}>*/}
                                        {/*    Conference Will Start In*/}
                                        {/*</div>*/}
                                        {/*<Link href={"https://zoom.us/j/95910779098?pwd=ckh0ODNnVWZNOUR4TG4vdGZPMERHZz09"}>*/}
                                        {/*    <div*/}
                                        {/*        style={{*/}
                                        {/*            fontSize: "2em",*/}
                                        {/*            display: windowWidth < 768 ? 'flex' : 'inline-flex',*/}
                                        {/*            flexDirection: windowWidth < 768 ? "row" : "row",*/}
                                        {/*            flexWrap: "wrap",*/}
                                        {/*            paddingLeft: windowWidth < 768 ? 0 : 20,*/}
                                        {/*            paddingRight: windowWidth < 768 ? 0 : 20,*/}
                                        {/*            paddingTop: 15,*/}
                                        {/*            paddingBottom: 15,*/}
                                        {/*            justifyContent: 'center',*/}
                                        {/*            cursor: "pointer",*/}
                                        {/*            color : Palette.IIDEC_RED,*/}
                                        {/*            textDecoration : "none"*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Join Conference*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                            }


                            {/*{*/}
                            {/*    windowWidth > 768 &&*/}
                            {/*    <BsChevronDown size={24} style={{*/}
                            {/*        color: "white",*/}
                            {/*        marginTop: -40*/}
                            {/*    }}/>*/}
                            {/*}*/}

                        </Col>
                    }

                </Row>
            </Element>

            {
                isMobile &&
                <Row style={{
                    // marginTop: cardMargin * -1,
                }}
                >
                    <Col
                        style={{
                            display: "flex",
                            alignItems: isMobile ? "center" : "flex-end",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            paddingRight: isMobile ? 0 : 50
                        }}
                        md={12}
                    >
                        <div>

                        </div>
                        {/*<div*/}
                        {/*    ref={cardRef}*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "column",*/}
                        {/*        background: "white",*/}
                        {/*        zIndex: 100,*/}
                        {/*        borderRadius: 10,*/}
                        {/*        color: Palette.IIDEC_RED,*/}
                        {/*        fontFamily: 'conthrax-bold',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <div style={{textAlign: "center"}}>*/}
                        {/*        Conference Will Start In*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            fontSize: "2em",*/}
                        {/*            display: windowWidth < 768 ? 'flex' : 'inline-flex',*/}
                        {/*            flexDirection: windowWidth < 768 ? "row" : "row",*/}
                        {/*            flexWrap: "wrap",*/}
                        {/*            paddingLeft: windowWidth < 768 ? 0 : 20,*/}
                        {/*            paddingRight: windowWidth < 768 ? 0 : 20,*/}
                        {/*            paddingTop: 15,*/}
                        {/*            paddingBottom: 15,*/}
                        {/*            justifyContent: 'center',*/}

                        {/*        }}*/}
                        {/*    >*/}

                        {/*        <div style={{*/}
                        {/*            textAlign: 'center',*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "column",*/}
                        {/*            alignItems: "center",*/}
                        {/*            marginLeft: 10,*/}
                        {/*            marginRight: 10*/}
                        {/*        }}>*/}
                        {/*            <div style={{*/}
                        {/*                height: "2em",*/}
                        {/*                width: "2em",*/}
                        {/*                borderRadius: "1em",*/}
                        {/*                backgroundColor: Palette.IIDEC_RED,*/}
                        {/*                color: "white",*/}
                        {/*                display: "flex",*/}
                        {/*                alignItems: "center",*/}
                        {/*                justifyContent: "center"*/}
                        {/*            }}>*/}
                        {/*                {*/}
                        {/*                    (daysLeft || daysLeft === 0) ?*/}
                        {/*                        `${daysLeft} `*/}
                        {/*                        :*/}
                        {/*                        ""*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*            <div style={{*/}
                        {/*                fontSize: "0.4em"*/}
                        {/*            }}>*/}
                        {/*                &nbsp;Days&nbsp;*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div style={{*/}
                        {/*            textAlign: 'center',*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "column",*/}
                        {/*            alignItems: "center",*/}
                        {/*            marginLeft: 10,*/}
                        {/*            marginRight: 10*/}
                        {/*        }}>*/}
                        {/*            <div style={{*/}
                        {/*                height: "2em",*/}
                        {/*                width: "2em",*/}
                        {/*                borderRadius: "1em",*/}
                        {/*                backgroundColor: Palette.IIDEC_RED,*/}
                        {/*                color: "white",*/}
                        {/*                display: "flex",*/}
                        {/*                alignItems: "center",*/}
                        {/*                justifyContent: "center"*/}
                        {/*            }}>*/}
                        {/*                {*/}
                        {/*                    (hourLeft || hourLeft === 0) ?*/}
                        {/*                        `${hourLeft}  `*/}
                        {/*                        :*/}
                        {/*                        "0"*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*            <div style={{*/}
                        {/*                fontSize: "0.4em"*/}
                        {/*            }}>*/}
                        {/*                &nbsp;Hour&nbsp;*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div style={{*/}
                        {/*            textAlign: 'center',*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "column",*/}
                        {/*            alignItems: "center",*/}
                        {/*            marginLeft: 10,*/}
                        {/*            marginRight: 10*/}
                        {/*        }}>*/}
                        {/*            <div style={{*/}
                        {/*                height: "2em",*/}
                        {/*                width: "2em",*/}
                        {/*                borderRadius: "1em",*/}
                        {/*                backgroundColor: Palette.IIDEC_RED,*/}
                        {/*                color: "white",*/}
                        {/*                display: "flex",*/}
                        {/*                alignItems: "center",*/}
                        {/*                justifyContent: "center"*/}
                        {/*            }}>*/}
                        {/*                {*/}
                        {/*                    (minuteLeft || minuteLeft === 0) ?*/}
                        {/*                        `${minuteLeft} `*/}
                        {/*                        :*/}
                        {/*                        "0"*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*            <div style={{*/}
                        {/*                fontSize: "0.4em"*/}
                        {/*            }}>*/}
                        {/*                Minutes*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div style={{*/}
                        {/*            textAlign: 'center',*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "column",*/}
                        {/*            alignItems: "center",*/}
                        {/*            marginLeft: 10,*/}
                        {/*            marginRight: 10*/}
                        {/*        }}>*/}
                        {/*            <div style={{*/}
                        {/*                height: "2em",*/}
                        {/*                width: "2em",*/}
                        {/*                borderRadius: "1em",*/}
                        {/*                backgroundColor: Palette.IIDEC_RED,*/}
                        {/*                color: "white",*/}
                        {/*                display: "flex",*/}
                        {/*                alignItems: "center",*/}
                        {/*                justifyContent: "center"*/}
                        {/*            }}>*/}
                        {/*                {*/}
                        {/*                    (secondLeft || secondLeft === 0) ?*/}
                        {/*                        `${secondLeft} `*/}
                        {/*                        :*/}
                        {/*                        "0"*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*            <div style={{*/}
                        {/*                fontSize: "0.4em"*/}
                        {/*            }}>*/}
                        {/*                Seconds*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
            }

        </div>

        {
            isMobile &&
            <Row style={{
                // marginTop: cardMargin * -1,
                backgroundColor: "#373d4b"
            }}>
                <Col md={12} style={{
                    marginTop: 50,
                    padding: 0, color: "white",
                    textAlign: "center",
                    marginBottom: 50
                }}>
                    <h3>
                        VIRTUAL EXHIBITION<br/>
                        8-15 DECEMBER 2021
                        <br/><br/>
                        VIRTUAL CONFERENCE<br/>
                        14-15 DECEMBER 2021<br/>
                    </h3>

                </Col>
            </Row>
        }


        <Row>
            <Col md={4} style={{padding: 0}}>
                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}
                    src={aboutUsImage}/>
            </Col>
            <Col
                md={8}
                style={{
                    backgroundColor: "black",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <p style={{
                    color: "white",
                    padding: 20,
                    marginTop: isMobile ? 50 : cardMargin,
                }}>

                    <br/>
                    <br/>

                    <div style={{fontFamily: 'conthrax-bold'}}>
                        <h4>About IIDEC 2021</h4>
                    </div>

                    <br/>
                    <br/>

                    Indonesia is located along the Pacific Ring of Fire and faces many natural threats including
                    earthquakes, tsunamis, volcanic eruptions, flooding, and droughts. The country has experienced
                    an
                    average of 290 significant natural disasters annually over the last 30 years.
                    Consequently, Indonesian government has modified its laws, regulations, and institutions to
                    better
                    manage disaster risk, and generate contingency plans that identify risks, describe the relief
                    response, and develop overall preparation.
                    <br/><br/>
                    As part of its commitments to support Government’s action in coping with disaster threats, <b>BRIN
                    (National Research and Innovation Agency)</b> will host <b>Indonesia International Disaster Expo
                    &
                    Conference (IIDEC 2021)</b> which will take place on a virtual platform from 8 - 15 December
                    2021.
                    <br/><br/>
                    IIDEC 2021 is the premiere Conference & Expo supporting a broad spectrum of technological
                    industry
                    perspectives from Disaster detection and preparedness, Emergency Management, Emergency Response,
                    Disaster Recovery and mitigation, Business Continuity, Resilience and more.

                    <br/>
                    <br/>

                </p>
            </Col>
        </Row>

        <Row style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Palette.IIDEC_RED,
            color: "white",
            paddingTop: 70,
            paddingBottom: 70
        }}>
            <Col
                style={{
                    padding: 30,
                    textAlign: 'left'
                }}
                md={8}>
                {/*<img*/}
                {/*    src={logo}*/}
                {/*    style={{*/}
                {/*        objectFit: 'contain',*/}
                {/*        backgroundColor: 'white',*/}
                {/*        width: 200,*/}
                {/*        padding: 20,*/}
                {/*        borderRadius: 5,*/}
                {/*        boxShadow: '5px 5px 5px rgba(0,0,0,.6666666666666666)',*/}
                {/*        marginBottom: 30*/}
                {/*    }}/>*/}

                <video
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    style={{
                        width: "100%",
                        height: 500,
                        background: "black",
                        marginBottom: 50
                    }}
                    src={"https://iidec.s3.ap-southeast-1.amazonaws.com/video/Iidec+Promo-1.mp4"}>

                </video>

                <h4>
                    <div style={{fontFamily: 'conthrax-bold'}}>Event Description</div>
                </h4>
                <p style={{fontSize: "1.1em"}}>

                    <br/>
                    <b>IIDEC 2021 Conference</b> aims to provide an opportunity for public and private sector to
                    engage
                    in three days of problem solving, planning and preparation, discussing current technological
                    solutions within the fields of Disaster Preparation, Response, Recovery, and Mitigation on
                    national
                    & global scale. The conference will promote dialogue between leading industry professionals,
                    providing opportunity to review and discuss public policies, procedures, and best practices, as
                    well
                    as review how emergency resources can best be shared and utilized to assist others when facing
                    catastrophic events across the country.
                </p>
                <p style={{fontSize: "1.1em"}}>
                    <b>IIDEC 2021 Exhibition</b> consists of display from exhibitors and represented companies and
                    organizations showcasing the most relevant and cutting-edge disaster & emergencies technologies,
                    solutions, and applications. The exhibition is packed on a virtual platform which shows 3D
                    booths
                    display in which visitor can navigate, walk down the halls, resembling real activities at
                    conventional exhibitions.
                </p>
            </Col>

        </Row>

        <div>
            <Row style={{
                paddingTop: 100,
                paddingBottom: 70,
                // backgroundColor : Palette.IIDEC_RED
            }}>
                <Col
                    style={{
                        textAlign: "center"
                    }}
                    md={12}>

                    <h4>
                        <div style={{
                            color: Palette.IIDEC_RED, fontFamily: 'conthrax-bold',
                            marginBottom: 60
                        }}>Innovative Features
                        </div>
                    </h4>

                    <img
                        style={{
                            objectFit: "contain",
                            width: "100%",
                            paddingLeft: isMobile ? 15 : 100,
                            paddingRight: isMobile ? 15 : 100,
                        }}
                        src={featureImages}/>
                </Col>
            </Row>
        </div>

        <div ref={conferenceAgendaRef}>
            <Element name="conference-agenda" className="element">
                <Row style={{
                    paddingTop: 50,
                    paddingBottom: 50,
                    backgroundColor: Palette.IIDEC_RED,
                    color: "white"
                }}>
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                        md={12}>
                        <h4>
                            <div style={{
                                fontFamily: 'conthrax-bold',
                                marginBottom: 60
                            }}>Event Program
                            </div>
                        </h4>
                    </Col>
                    <Col md={2}></Col>
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        md={4}>
                        <img
                            style={{
                                width: "100%",
                                height: 300,
                                objectFit: "contain",
                                marginBottom: 30
                            }}
                            src={virtualConferenceImage}/>
                        <b>Virtual Conference</b>
                    </Col>
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        md={4}>
                        <img
                            style={{
                                width: "100%",
                                height: 300,
                                objectFit: "contain",
                                marginBottom: 30
                            }}
                            src={virtualExhibitionImage}/>
                        <b>Virtual Exhibition</b>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Element>
        </div>


        <Row style={{
            paddingTop: 150,
            paddingBottom: 150,
            // backgroundColor : Palette.IIDEC_RED
        }}>
            <Col
                style={{
                    textAlign: "center"
                }}
                md={12}>
                <h4>
                    <div style={{
                        color: Palette.IIDEC_RED, fontFamily: 'conthrax-bold',
                        marginBottom: 60
                    }}>Why must-attend Exhibition?
                    </div>
                </h4>
            </Col>
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                md={3}>
                <BsMegaphoneFill
                    style={{height: 150, color: Palette.IIDEC_RED}}
                    size={60}/>
                <h4>Wide Coverage</h4>
                <p style={{
                    textAlign: "center"
                }}>
                    Wide coverage of digital publication, exposure to more than 100 national companies
                </p>
            </Col>
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                md={3}>
                <FaUsers
                    style={{height: 150, color: Palette.IIDEC_RED}}
                    size={60}/>
                <h4>More Participants</h4>
                <p style={{
                    textAlign: "center"
                }}>
                    Inviting more than 2000 participants
                </p>
            </Col>
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                md={3}>
                <GiPartyPopper
                    style={{height: 150, color: Palette.IIDEC_RED}}
                    size={60}/>
                <h4>Great Exposure</h4>
                <p style={{
                    textAlign: "center"
                }}>
                    Exposure to Indonesian & Global Business Leaders
                </p>
            </Col>
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                md={3}>
                <FaMedal
                    style={{height: 150, color: Palette.IIDEC_RED}}
                    size={60}/>
                <h4>New Experience</h4>
                <p style={{
                    textAlign: "center"
                }}>
                    Unique & attractive digital experience with virtual exhibition event
                </p>
            </Col>
        </Row>

        {/*<Row style={{*/}
        {/*    paddingTop: 150,*/}
        {/*    paddingBottom: 150,*/}
        {/*    backgroundColor: Palette.IIDEC_RED,*/}
        {/*    color: "white"*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col*/}
        {/*        style={{*/}
        {/*            display: "flex",*/}
        {/*            flexDirection: "column",*/}
        {/*            alignItems: "center",*/}
        {/*            paddingBottom: 60*/}
        {/*        }}*/}
        {/*        md={12}>*/}
        {/*        <h4>*/}
        {/*            <div style={{*/}
        {/*                fontFamily: 'conthrax-bold',*/}
        {/*            }}>*/}
        {/*                Conference Agenda*/}
        {/*            </div>*/}
        {/*        </h4>*/}
        {/*        <div style={{*/}
        {/*            fontFamily: 'conthrax-bold',*/}
        {/*        }}>*/}
        {/*            Coming Soon*/}
        {/*        </div>*/}
        {/*    </Col>*/}

        {/*</Row>*/}

        <Row style={{
            // paddingTop: 150,
            paddingBottom: 150,
            display: instagramLength ? null : "none"
        }}
        >
            <Col
                style={{
                    textAlign: "center"
                }}
                md={12}>
                <h4>
                    <div style={{
                        fontFamily: 'conthrax-bold',
                        marginBottom: 60
                    }}>News Update
                    </div>
                </h4>
            </Col>

            <Col
                md={3}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                    <div style={{
                        color: "white",
                        backgroundColor: Palette.IIDEC_RED,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 5,
                        paddingRight: 5,
                        textAlign: "center",
                        width: "100%"
                    }}>
                        BRIN Hadirkan Teknologi Kebencanaan di IIDEC 2021
                    </div>
                    <div style={{
                        width: " 100%",
                        border: "orange 1px solid",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: Palette.IIDEC_RED,
                    }}>
                        <small>
                            <a
                                style={{
                                    color: "black",
                                    wordBreak: "break-all"
                                }}
                                href={"https://mediaindonesia.com/humaniora/452211/brin-hadirkan-teknologi-kebencanaan-di-iidec-2021"}>
                                https://mediaindonesia.com/humaniora/452211/brin-hadirkan-teknologi-kebencanaan-di-iidec-2021
                            </a>
                        </small>

                    </div>
                </div>
            </Col>

            <Col
                md={3}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                    <div style={{
                        color: "white",
                        backgroundColor: Palette.IIDEC_RED,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 5,
                        paddingRight: 5,
                        textAlign: "center",
                        width: "100%"
                    }}>
                        BRIN Siap Pamerkan Hasil Riset Terkini di Gelaran International Disaster Expo & Conference 2021
                    </div>
                    <div style={{
                        width: " 100%",
                        border: "orange 1px solid",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: Palette.IIDEC_RED,
                    }}>
                        <small>
                            <a
                                style={{
                                    color: "black",
                                    wordBreak: "break-all"
                                }}
                                href={"https://www.tribunnews.com/sains/2021/12/08/brin-siap-pamerkan-hasil-riset-terkini-di-gelaran-international-disaster-expo-conference-2021"}>
                                https://www.tribunnews.com/sains/2021/12/08/brin-siap-pamerkan-hasil-riset-terkini-di-gelaran-international-disaster-expo-conference-2021</a>
                        </small>

                    </div>
                </div>
            </Col>

            <Col
                md={3}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                    <div style={{
                        color: "white",
                        backgroundColor: Palette.IIDEC_RED,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 5,
                        paddingRight: 5,
                        textAlign: "center",
                        width: "100%"
                    }}>
                        BRIN Hadirkan Teknologi Kebencanaan di IIDEC 2021
                    </div>
                    <div style={{
                        width: " 100%",
                        border: "orange 1px solid",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: Palette.IIDEC_RED,
                    }}>
                        <small>
                            <a
                                style={{
                                    color: "black",
                                    wordBreak: "break-all"
                                }}
                                href={"https://headtopics.com/id/brin-hadirkan-teknologi-kebencanaan-di-iidec-2021-22765250"}>
                                https://headtopics.com/id/brin-hadirkan-teknologi-kebencanaan-di-iidec-2021-22765250
                            </a>
                        </small>

                    </div>
                </div>
            </Col>

            <Col
                md={3}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                    <div style={{
                        color: "white",
                        backgroundColor: Palette.IIDEC_RED,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 5,
                        paddingRight: 5,
                        textAlign: "center",
                        width: "100%"
                    }}>
                        Di IIDEC 2021, BRIN Hadirkan Teknologi Kebencanaan Terkini
                    </div>
                    <div style={{
                        width: " 100%",
                        border: "orange 1px solid",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 15,
                        paddingBottom: 15,
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: Palette.IIDEC_RED,
                    }}>
                        <small>
                            <a
                                style={{
                                    color: "black",
                                    wordBreak: "break-all"
                                }}
                                href={"https://www.beritasatu.com/digital/864207/di-iidec-2021-brin-hadirkan-teknologi-kebencanaan-terkini"}>
                                https://www.beritasatu.com/digital/864207/di-iidec-2021-brin-hadirkan-teknologi-kebencanaan-terkini </a>
                        </small>

                    </div>
                </div>
            </Col>


        </Row>

        <Row style={{
            // paddingTop: 150,
            paddingBottom: 150,
            display: instagramLength ? null : "none"
        }}
        >
            <Col
                style={{
                    textAlign: "center"
                }}
                md={12}>
                <h4>
                    <div style={{
                        fontFamily: 'conthrax-bold',
                        marginBottom: 60
                    }}>Social Media Update
                    </div>
                </h4>
            </Col>

            <InstagramFeed
                ref={iFeedRef}
                style={{
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
                counter="6"

                token="IGQVJYNlR2U2ZAjVV9Xd0FRWDFsRXlhNWd6YnZAmWWxmYngwVWRBakRhOEZADMUZAwMTBKWGhxd1JrVE5Sci1uQThnQU1TUkp1alV3cHZAqTFVQd2NGdkhkckozeHN4M0dsajJORGY3dHh1am55V0l4bVpsMQZDZD"/>
            {/*token="IGQVJWSE5LMk1udktzSWRtczhWTjRESGczaE9qU21VMXF2cDR2eEFvNnk5aXp3VzdQOVoycUo2ZAktwcjdQUDlhWG1BMmZAfZAVVSOW54RV9kbmNCeExxNnBHd1FvTFppdVRyZAC1QbVVFNGJmdVlKaGx4dgZDZD"/>*/}

        </Row>

        <Row
            style={{
                paddingTop: 20,
                paddingBottom: 100,
                // backgroundColor : Palette.IIDEC_RED
            }}>
            <Col
                style={{
                    textAlign: "center"
                }}
                md={12}>
                <div style={{
                    fontFamily: 'conthrax-bold',
                    marginBottom: 60
                }}>
                    <h4>Hosted By : </h4>
                </div>

                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 130
                    }}
                    src={logoBrin}/>

            </Col>

            <Col
                style={{
                    textAlign: "center"
                }}
                md={12}>
                <div style={{
                    fontFamily: 'conthrax-bold',
                    marginBottom: 30,
                    marginTop: 60
                }}>
                    <h4>Supported By : </h4>
                </div>

            </Col>

            <Col md={4}>
                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 120,
                        padding: 20
                    }}
                    src={sponsor1}/>
            </Col>
            <Col md={4}>
                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 120,
                        padding: 10
                    }}
                    src={sponsor2}/>
            </Col>
            <Col md={4}>
                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 120,
                        padding: 20

                    }}
                    src={sponsor3}/>
            </Col>

            <Col
                style={{
                    textAlign: "center"
                }}
                md={12}>
                <div style={{
                    fontFamily: 'conthrax-bold',
                    marginBottom: 30,
                    marginTop: 60
                }}>
                    <h4>Organized By : </h4>
                </div>

                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 110
                    }}
                    src={organizer}/>


            </Col>

        </Row>

        <div>
            <Element name="sponsorship" className="element">
                <Row
                    style={{
                        paddingTop: 50,
                        paddingBottom: 50,
                        backgroundColor: "#373D4D"
                    }}
                >
                    <Col
                        style={{
                            textAlign: "center",
                            color: "white"
                        }}
                        md={12}>
                        <h4>
                            <div style={{
                                fontFamily: 'conthrax-bold',
                                marginBottom: 10
                            }}>
                                Contact Us
                            </div>
                        </h4>

                        <div style={{padding: 30}}>
                            <Form>
                                <Row>
                                    <Col
                                        md={6}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                            className="mb-3" controlId="formBasicEmail">
                                            <Form.Label style={{...styles.boldText}}>Full Name</Form.Label>
                                            <Form.Control
                                                placeholder={"Full Name"}
                                                value={contactFullName ? contactFullName : ""}
                                                onChange={(e) => {
                                                    setContactFullName(e.target.value)
                                                }}
                                                style={{...styles.normalText}}/>

                                        </Form.Group>
                                    </Col>
                                    <Col
                                        md={6}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                            className="mb-3">
                                            <Form.Label style={{...styles.boldText}}>Company</Form.Label>
                                            <Form.Control
                                                placeholder={"Company"}
                                                value={contactCompany ? contactCompany : ""}
                                                onChange={(e) => {
                                                    setContactCompany(e.target.value)
                                                }}
                                                style={{...styles.normalText}}/>

                                        </Form.Group>
                                    </Col>
                                    <Col
                                        md={6}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                            className="mb-3">
                                            <Form.Label style={{...styles.boldText}}>Email</Form.Label>
                                            <Form.Control
                                                placeholder={"Email"}
                                                value={contactEmail ? contactEmail : ""}
                                                onChange={(e) => {
                                                    setContactEmail(e.target.value)
                                                }}
                                                style={{...styles.normalText}}/>

                                        </Form.Group>
                                    </Col>
                                    <Col
                                        md={6}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                            className="mb-3">
                                            <Form.Label style={{...styles.boldText}}>Phone</Form.Label>
                                            <Form.Control
                                                placeholder={"Phone / Whatsapp"}
                                                value={contactPhone ? contactPhone : ""}
                                                onChange={(e) => {
                                                    setContactPhone(e.target.value)
                                                }}
                                                style={{...styles.normalText}}/>

                                        </Form.Group>
                                    </Col>
                                    <Col
                                        md={12}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                            className="mb-3">
                                            <Form.Label style={{...styles.boldText}}>Subject</Form.Label>
                                            <Form.Control
                                                placeholder={"Subject"}
                                                value={contactSubject ? contactSubject : ""}
                                                onChange={(e) => {
                                                    setContactSubject(e.target.value)
                                                }}
                                                style={{...styles.normalText}}/>

                                        </Form.Group>
                                    </Col>
                                    <Col
                                        md={12}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}
                                            className="mb-3">
                                            <Form.Label style={{...styles.boldText}}>Message</Form.Label>
                                            <Form.Control
                                                rows={4}
                                                as={"textarea"}
                                                placeholder={"Your Message"}
                                                value={contactMessage ? contactMessage : ""}
                                                onChange={(e) => {
                                                    setContactMessage(e.target.value)
                                                }}
                                                style={{...styles.normalText}}/>

                                        </Form.Group>
                                    </Col>

                                    <Col
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end"
                                        }}
                                        md={12}>
                                        <CustomButton
                                            onClick={() => {
                                                contactFormSubmit()
                                            }}
                                        >
                                            Send Message
                                        </CustomButton>
                                    </Col>

                                </Row>

                            </Form>

                        </div>


                    </Col>
                </Row>
            </Element>
        </div>


        <Footer/>

        <Modal show={isRegisterShown} onHide={() => {
            setRegisterShown(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}>Visitor Registration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{...styles.normalText}}>
                    Already have an account? <a href={'#'}
                                                onClick={() => {
                                                    setLoginShown(true)
                                                    setRegisterShown(false)
                                                }}>Login</a>
                </div>
                <Form style={{marginTop: 5}}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                        <Form.Control type="email"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              email: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.email}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.email}
                        </Form.Control.Feedback>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Password : </Form.Label>
                        <Form.Control type="password"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              password: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.password}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Password Confirmation : </Form.Label>
                        <Form.Control type="password"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              confirm_password: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.confirm_password}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.confirm_password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{...styles.boldText}}>Full Name : </Form.Label>
                        <Form.Control type="text"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              fullname: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.fullname}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.fullname}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{...styles.boldText}}>Phone Number / Whatsapp :</Form.Label>
                        <Form.Control type="tel"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              phone_num: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.phone_num}
                                      style={{...styles.normalText}}/>

                        <Form.Label>
                            Please start with country code (Ex. +62)
                        </Form.Label>


                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.phone_num}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Job Title: </Form.Label>
                        <Form.Control type="text"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              job: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.job}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.job}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Company : </Form.Label>
                        <Form.Control type="text"
                                      onChange={(e) => {
                                          setRegisterCreds({
                                              ...registerCreds,
                                              industry: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.industry}
                                      style={{...styles.normalText}}/>


                        <Form.Control.Feedback type="invalid">
                            {errors.industry}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Gender : </Form.Label>
                        <Form.Select
                            onChange={(e) => {
                                setRegisterCreds({
                                    ...registerCreds,
                                    gender: e.target.value
                                })
                            }}
                            value={registerCreds.gender ? registerCreds.gender : 'Select Gender . . .'}
                            isInvalid={!!errors.gender}
                        >
                            <option disabled={true}>Select Gender . . .</option>
                            <option value={"M"}>Male</option>
                            <option value={"F"}>Female</option>
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.gender}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Form>

                <div style={{...styles.normalText}}>
                    Already have an account? <a href={'#'}
                                                onClick={() => {
                                                    setLoginShown(true)
                                                    setRegisterShown(false)
                                                }}>Login</a>
                </div>

                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setRegisterShown(false)
                        }}>
                    Close
                </Button>
                <CustomButton variant="primary"
                              disabled={isLoading}
                              style={{...styles.boldText, width: 86}}
                              onClick={attemptRegister}>
                    {
                        isLoading ?
                            <Spinner animation="border" size={'sm'}/> :
                            'Register'
                    }
                </CustomButton>
                <Row>
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                        md={12}>
                        <div style={{
                            fontFamily: 'conthrax-bold',
                            marginBottom: 30,
                            marginTop: 60
                        }}>
                            <h4>Supported By : </h4>
                        </div>

                    </Col>

                    <Col md={4}>
                        <img
                            style={{
                                objectFit: "contain",
                                width: "100%",
                                height: 120,
                                padding: 20
                            }}
                            src={sponsor1}/>
                    </Col>
                    <Col md={4}>
                        <img
                            style={{
                                objectFit: "contain",
                                width: "100%",
                                height: 120,
                                padding: 0
                            }}
                            src={sponsor2}/>
                    </Col>
                    <Col md={4}>
                        <img
                            style={{
                                objectFit: "contain",
                                width: "100%",
                                height: 120,
                                padding: 20

                            }}
                            src={sponsor3}/>
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal>

        <Modal show={isResetPasswordShown} onHide={() => {
            setResetPasswordShown(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{...styles.boldText}}>Verification Code : </Form.Label>
                    <div style={{textAlign: 'center'}}>
                        <ReactCodeInput
                            type='text'
                            forceUppercase={true}
                            fields={5}
                            onChange={(value) => setResetPasswordCreds({
                                ...resetPasswordCreds,
                                token: value
                            })}
                        />
                    </div>

                    <div style={{color: 'red', textAlign: 'center'}}>
                        {errors.verification_code}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{...styles.boldText}}>Password : </Form.Label>
                    <Form.Control type="password"
                                  onChange={(e) => {
                                      setResetPasswordCreds({
                                          ...resetPasswordCreds,
                                          new_password: e.target.value
                                      })
                                  }}
                                  isInvalid={!!errors.password}
                                  style={{...styles.normalText}}/>

                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{...styles.boldText}}>Password Confirmation : </Form.Label>
                    <Form.Control type="password"
                                  onChange={(e) => {
                                      setResetPasswordCreds({
                                          ...resetPasswordCreds,
                                          confirm_password: e.target.value
                                      })
                                  }}
                                  isInvalid={!!errors.confirm_password}
                                  style={{...styles.normalText}}/>

                    <Form.Control.Feedback type="invalid">
                        {errors.confirm_password}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <div style={{flex: 1}}>
                    <a href={"#"} onClick={() => {
                        if (remainingTime === 0) {
                            attemptForgotPassword()
                        }
                    }} style={{
                        color: remainingTime > 0 ? "grey" : undefined
                    }}>
                        Resend Verification Code {remainingTime > 0 ? `(${remainingTime})` : null}
                    </a>
                </div>

                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setForgotPasswordShown(false)
                        }}>
                    Close
                </Button>

                <CustomButton variant="primary"
                              disabled={isLoading}
                              style={{...styles.boldText, width: 100}}
                              onClick={attemptResetPassword}>
                    {
                        isLoading ?
                            <Spinner animation="border" size={'sm'}/> :
                            'Submit'
                    }
                </CustomButton>
            </Modal.Footer>
        </Modal>

        <Modal show={isForgotPasswordShown} onHide={() => {
            setForgotPasswordShown(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                    <Form.Control type="email"
                                  onChange={(e) => {
                                      setForgotPasswordCreds({
                                          email: e.target.value
                                      })
                                  }}
                                  isInvalid={!!errors.email}
                                  style={{...styles.normalText}}/>

                    <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setForgotPasswordShown(false)
                        }}>
                    Close
                </Button>

                <CustomButton variant="primary"
                              disabled={isLoading}
                              style={{...styles.boldText, width: 190}}
                              onClick={attemptForgotPassword}>
                    {
                        isLoading ?
                            <Spinner animation="border" size={'sm'}/> :
                            'Send Validation Code'
                    }
                </CustomButton>
            </Modal.Footer>
        </Modal>

        <Modal show={isLoginShown} onHide={() => {
            setLoginShown(false)
        }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{...styles.boldText}}>Visitor Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*<div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>*/}
                {/*    {errors.form}*/}
                {/*</div>*/}

                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{...styles.boldText}}>Email :</Form.Label>
                        <Form.Control type="email"
                                      onChange={(e) => {
                                          setLoginCreds({
                                              ...loginCreds,
                                              email: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.email}
                                      style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid" style={{...styles.normalText}}>
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{...styles.boldText}}>Password :</Form.Label>
                        <Form.Control type="password"
                                      onChange={(e) => {
                                          setLoginCreds({
                                              ...loginCreds,
                                              password: e.target.value
                                          })
                                      }}
                                      isInvalid={!!errors.password} style={{...styles.normalText}}/>

                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>

                <div style={{...styles.normalText}}>
                    Don't have an account? <a href={'#'}
                                              onClick={() => {
                                                  setLoginShown(false)
                                                  setRegisterShown(true)
                                              }}>Sign up</a>
                </div>

                <div style={{...styles.normalText, marginTop: 15, color: 'red', marginBottom: 5}}>
                    {errors.form}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{flex: 1}}>
                    <a href={'#'}
                       onClick={() => {
                           setLoginShown(false)
                           setForgotPasswordShown(true)
                       }}>Forgot Password?</a>
                </div>

                <Button variant="secondary"
                        style={{...styles.boldText}}
                        onClick={() => {
                            setLoginShown(false)
                        }}>
                    Close
                </Button>

                <CustomButton variant="primary"
                              disabled={isLoading}
                              style={{...styles.boldText, width: 80}}
                              onClick={attemptLogin}>
                    {
                        isLoading ?
                            <Spinner animation="border" size={'sm'}/> :
                            'Login'
                    }
                </CustomButton>

            </Modal.Footer>
        </Modal>

    </div>
}
